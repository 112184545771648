import { Box } from '@mui/material';
import { Typography } from 'lux/components';

import * as styles from './WorkTimeSum.styles';
import { WorkTimeSumProps } from './WorkTimeSum.types';

export const WorkTimeSum = ({ children, sx, isDimmed }: WorkTimeSumProps) => (
  <Box sx={() => ({ ...styles.workTimeSum(isDimmed), ...sx })}>
    <Typography variant="buttonSmall">{children}</Typography>
  </Box>
);
