import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { AppMessages, Translation } from 'i18n/messages';

export const useWorkTimeTooltipMessage = (
  isOutsideProjectDates: boolean,
  isDataMismatch: boolean,
  mismatchWorkTimeText: string,
) => {
  const getMessageTranslationId = (
    isDataMismatch: boolean,
    isTimesheetEditBlocked: boolean,
    isOutsideProjectDates: boolean,
  ): Translation => {
    if (isDataMismatch) return 'projectDetails.timesheet.tooltip.data.mismatch';
    if (isTimesheetEditBlocked) return 'projectDetails.timesheet.confirmedInvoice.title';
    if (isOutsideProjectDates) return 'projectDetails.timesheet.dayOutsideProjectDates.title';
    return 'projectDetails.timesheet.dayOutsidePersonAssignment.title';
  };

  const { formatMessage } = useLocale();
  const { isTimesheetEditBlocked, snapshotCreateDate } = useProjectTimesheetData();

  const tooltipMessageTranslationId = getMessageTranslationId(
    isDataMismatch,
    isTimesheetEditBlocked,
    isOutsideProjectDates,
  );

  const message = formatMessage(
    {
      id: AppMessages[tooltipMessageTranslationId],
    },
    {
      date: snapshotCreateDate,
      value: mismatchWorkTimeText,
    },
  );
  return message;
};
