import { Table } from 'lux/components';

import { HourType } from 'api/types/HourType.enum';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { useLocale } from 'hooks/useLocale/useLocale';
import { calculateWorkTimeRate, formatWorkTime } from 'utils/workTime/workTime';
import { WorkStatementTableCell as TableCell } from '../workStatementTableCell/WorkStatementTableCell';

import * as styles from './WorkTimeDetailsRow.styles';
import { WorkTimeDetailsRowProps } from './WorkTimeDetailsRow.types';

export const WorkTimeDetailsRow = ({
  workTime,
  isLastRow = false,
  hasPadding = false,
  role,
}: WorkTimeDetailsRowProps) => {
  const { type, rateType, ratePercentage, amount, total } = workTime;
  const { formatPrice } = useBillingSummaryPrice();
  const { t } = useLocale();

  return (
    <Table.Row sx={styles.workTimeDetailsRow(isLastRow)}>
      <TableCell autoWidth sx={hasPadding ? {} : styles.firstCell}>
        {type === HourType.regular && t('projectDetails.timesheet.regularWorks.rate', { rate: ratePercentage })}
        {type === HourType.overhour && t('projectDetails.timesheet.overtime.rate', { rate: ratePercentage })}
      </TableCell>
      {role && <TableCell autoWidth>{role}</TableCell>}
      <TableCell autoWidth sx={styles.workTimeCell}>
        {formatWorkTime(amount, rateType)}
      </TableCell>
      <TableCell>{formatPrice(calculateWorkTimeRate(workTime))}</TableCell>
      <TableCell>{formatPrice(total)}</TableCell>
    </Table.Row>
  );
};
