import { useIntl } from 'react-intl';

import { WorkStatementTeamMemberWorkTimeEntry } from 'api/types/BillingSummary.types';
import { AppMessages } from 'i18n/messages';
import { calculateWorkTimeRate, formatWorkTime } from 'utils/workTime/workTime';
import { useBillingSummaryPrice } from '../useBillingSummaryPrice/useBillingSummaryPrice';

import { RateSummary, UseBillingSummaryMemberWorkTimeProps } from './useBillingSummaryMemberWorkTime.types';

const hasDifferentRates = (workTimeEntries: WorkStatementTeamMemberWorkTimeEntry[]) => {
  const baseRate = calculateWorkTimeRate(workTimeEntries[0]);
  return workTimeEntries.some((workTime) => calculateWorkTimeRate(workTime) !== baseRate);
};

const hasDifferentRateTypes = (workTimeEntries: WorkStatementTeamMemberWorkTimeEntry[]) => {
  const baseRate = workTimeEntries[0];
  return workTimeEntries.some((workTime) => workTime.rateType !== baseRate.rateType);
};

export const useBillingSummaryMemberWorkTime = ({
  items: workTimeEntries,
  displayRateType,
  displayTotalWorkTime,
  useDashForMultipleWorkTime,
}: UseBillingSummaryMemberWorkTimeProps): RateSummary => {
  const { formatMessage } = useIntl();
  const { formatPrice } = useBillingSummaryPrice();

  if (!workTimeEntries?.length) {
    return {
      totalWorkTime: '0',
      rateText: formatPrice(0),
    };
  }

  const totalWorkTime = formatWorkTime(displayTotalWorkTime, displayRateType);

  if (useDashForMultipleWorkTime && workTimeEntries.length > 1) {
    return {
      totalWorkTime,
      rateText: '-',
    };
  } else if (hasDifferentRates(workTimeEntries) || hasDifferentRateTypes(workTimeEntries)) {
    return {
      totalWorkTime,
      rateText: formatMessage({ id: AppMessages['projectDetails.billingSummary.multipleRates'] }),
    };
  }

  return {
    totalWorkTime,
    rateText: formatPrice(workTimeEntries[0].rateValue),
  };
};
