import { Box, Typography } from '@mui/material';
import { Logo } from 'lux/components';

import { Translation } from '../translation/Translation';

import * as styles from './MaintenanceMode.styles';

export const MaintenanceMode = () => (
  <Box sx={styles.wrapper}>
    <Logo height="64" width="auto" />
    <Typography variant="h3" align="center" sx={styles.title}>
      <Translation id="maintenance.title" />
    </Typography>
    <Typography variant="h6" align="center">
      <Translation id="maintenance.message" />
    </Typography>
  </Box>
);
