import { SxProps } from '@mui/system';

export const workTimeDetailsRow = (isLast = false) => ({
  backgroundColor: 'surface.paper.default',
  borderBottom: isLast ? '1px solid' : undefined,
  borderBottomColor: isLast ? 'border.default' : undefined,
});

export const firstCell = {
  '&&&&': {
    paddingLeft: 6,
  },
};

export const workTimeCell: SxProps = {
  '&.MuiTableCell-body.MuiTableCell-root': {
    width: '120px',
  },
};
