import { useState } from 'react';

import FocusTrap from '@mui/base/FocusTrap';
import { Box, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { Avatar, MenuItem, Tooltip } from 'lux/components';

import { useAvailabilityPeople } from '../useAvailabilityPeople/useAvailabilityPeople';

import * as styles from './PeopleListPopover.styles';
import { PeopleListTooltipTitle } from './peopleListTooltipTitle/PeopleListTooltipTitle';

export const PeopleListPopover = () => {
  const { anchor, onPopoverClose, selectedValue } = useAvailabilityPeople();
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Popper
      open={Boolean(anchor)}
      anchorEl={anchor}
      placement="top"
      transition
      sx={styles.popper}
      onKeyDown={(e) => {
        if (e.code === 'Escape') {
          onPopoverClose();
        }
      }}
      id="availability-people-list"
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onPopoverClose}>
          <Grow {...TransitionProps}>
            <div>
              <FocusTrap open>
                <Paper sx={styles.contentContainer}>
                  {selectedValue?.map(({ firstName, lastName, picture, employeeId, assignment, proposals }) => (
                    <Tooltip
                      arrow
                      placement="left"
                      key={employeeId}
                      title={
                        (assignment || proposals.length > 0) && (
                          <PeopleListTooltipTitle assignment={assignment} proposals={proposals} />
                        )
                      }
                    >
                      <Box>
                        <MenuItem
                          tabIndex={0}
                          onClick={() => window.open(`/people/${employeeId}`, '_blank')}
                          key={employeeId}
                          avatar={<Avatar size="md" alt={`${firstName} ${lastName}`} image={picture} />}
                        >{`${firstName} ${lastName}`}</MenuItem>
                      </Box>
                    </Tooltip>
                  ))}
                </Paper>
              </FocusTrap>
              <Box sx={styles.arrow} ref={setArrowRef} className="MuiPopper-arrow" />
            </div>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
