import { Styles } from 'styles/theme';

export const wrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1rem',
  padding: '1.25rem',
};

export const inputsWrapper: Styles = {
  display: 'inline-flex',
  gap: '5px',
};

export const percentageRateInput: Styles = {
  minWidth: '100px',
  width: '100px',
};

export const actionButtonsWrapper: Styles = {
  display: 'flex',
  gap: '5px',
  justifyContent: 'flex-end',
};

export const confirmation: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  p: 1.5,
};

export const confirmationButton: Styles = {
  width: 1,
};

export const rateTypeLabel: Styles = {
  fontSize: 14,
  lineHeight: '18px',
  fontWeight: 500,
};

export const rateType: Styles = {
  fontSize: 14,
  lineHeight: '18px',
  color: 'text.secondary',
};
