import { HourType } from 'api/types/HourType.enum';
import { RATE_TYPES } from 'api/types/Rate.types';
import { Timesheet } from 'api/types/Timesheet.types';
import {
  TimesheetAssignment,
  TimesheetAssignmentWorkDay,
} from '../context/projectTimesheet/projectTimesheetDataContext/ProjectTimesheetDataContext.types';

export const processWorkTimeData = (teamMemberWorkDays: TimesheetAssignment['workDays']) => {
  const groupedRates: TimesheetAssignment['rates'] = Object.values(HourType).reduce(
    (acc, hourType) => ({ ...acc, [hourType]: [] }),
    {} as TimesheetAssignment['rates'],
  );

  const uniqueWorkTimeType = new Set<string>();

  teamMemberWorkDays.forEach(({ items }) => {
    items.forEach(({ type, ratePercentage, rateType }) => {
      const itemKey = `${ratePercentage}-${rateType}`;

      if (!uniqueWorkTimeType.has(itemKey)) {
        if (type !== HourType.absence && type !== HourType.holiday) {
          uniqueWorkTimeType.add(itemKey);
        }
        groupedRates[type].push({ ratePercentage: ratePercentage, rateType: rateType });
      }
    });
  });

  return {
    groupedRates,
    hasMultipleWorkTimeEntries: uniqueWorkTimeType.size > 1,
  };
};

export const mapToAssignmentWorkTimeArray = (
  assignments: Timesheet['workStatements'][number]['assignments'],
): TimesheetAssignment[] =>
  assignments.map((assignment) => {
    const { groupedRates, hasMultipleWorkTimeEntries } = processWorkTimeData(assignment.billingDays);
    return {
      employeeId: assignment.employeeId,
      role: assignment.role,
      name: `${assignment.firstName} ${assignment.lastName}`,
      hasMultipleWorkTimeEntries: hasMultipleWorkTimeEntries,
      teamMemberHours: assignment.billingDays,
      displayTotalWorkTime: assignment.displayTotalWorkTime,
      displayRateType: assignment.displayRateType,
      rates: groupedRates,
      workDays: assignment.billingDays,
    };
  });

export const getDisplayRateTypeUnit = (workDays: TimesheetAssignmentWorkDay[], workDayIndex: number) => {
  if (
    workDays.length === 0 ||
    !workDays[workDayIndex] ||
    workDays[workDayIndex].displayDailyRateType === RATE_TYPES.daily
  ) {
    return;
  }
  const firstRateType = workDays[0].displayDailyRateType;
  const hasDifferentRateTypes = workDays.some((day) => day.displayDailyRateType !== firstRateType);

  return hasDifferentRateTypes ? RATE_TYPES.hourly : undefined;
};
