export const MAINTENANCE_MODE_KEY = 'isMaintenanceMode';

export const getMaintenanceMode = (): boolean => localStorage.getItem(MAINTENANCE_MODE_KEY) === 'true';

export const setMaintenanceMode = (isEnabled: boolean): void => {
  const currentValue = getMaintenanceMode();
  if (currentValue === isEnabled) return;

  if (isEnabled) {
    localStorage.setItem(MAINTENANCE_MODE_KEY, 'true');
  } else {
    localStorage.removeItem(MAINTENANCE_MODE_KEY);
  }
  window.dispatchEvent(new Event(MAINTENANCE_MODE_KEY));
};
