import { Box, Stack } from '@mui/material';
import { Button, Checkbox, Typography } from 'lux/components';
import { InfoFilledIcon } from 'lux/icons';

import { InvoiceStatusEnum, WorkStatement } from 'api/types/BillingSummary.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { AppMessages } from 'i18n/messages';
import { Translation } from 'ui/translation/Translation';
import { WorkStatementStatusBadge } from 'ui/workStatementStatusBadge/WorkStatementStatusBadge';
import { BillingSummaryActions } from '../billingSummaryActions/BillingSummaryActions';

import * as styles from './BillingSummaryWorkStatement.styles';
import { BillingSummaryWorkStatementProps } from './BillingSummaryWorkStatement.types';
import { WorkStatementByMember } from './workStatementByMember/WorkStatementByMember';
import { WorkStatementByRole } from './workStatementByRole/WorkStatementByRole';
import { WorkStatementSummary } from './workStatementSummary/WorkStatementSummary';

const renderWorkStatement = (workStatement: WorkStatement) => {
  if (workStatement.roles) {
    return <WorkStatementByRole workStatement={workStatement} />;
  } else if (workStatement.assignments) {
    return <WorkStatementByMember workStatement={workStatement} />;
  }
};

export const BillingSummaryWorkStatement = ({
  workStatement,
  billingCycle,
  onClick,
  isClientView = false,
  onCheckboxChange,
  invoiceData,
}: BillingSummaryWorkStatementProps) => {
  const { snapshotCreateDate, showWorkStatementSnapshot } = useProjectBillingSummaryData();
  const { isReport } = useProjectBillingSummaryData();
  const { projectDetails } = useProjectDetails();
  const { formatMessage } = useLocale();
  const isCheckboxDisabled = workStatement.invoice?.status !== InvoiceStatusEnum.approved_by_pm;

  const handleChange = (checked: boolean, invoiceId?: string) => {
    if (!onCheckboxChange || !billingCycle || !invoiceId) {
      return Promise.resolve();
    }
    return onCheckboxChange({
      checked,
      invoiceId,
      projectId: projectDetails.id,
      projectName: projectDetails.name,
      amount: workStatement.total,
      currency: projectDetails.currency,
      billingCycle,
    });
  };

  const isProjectChecked = (currentProjectId: string) =>
    !!invoiceData?.some(({ projectId }) => projectId === currentProjectId);

  return (
    <>
      {!isReport && (
        <Stack sx={styles.workStatementHeader}>
          <Stack>
            <Stack direction="row" alignItems="center">
              {isClientView && (
                <Checkbox
                  disabled={isCheckboxDisabled}
                  onChange={(_, checked) => handleChange(checked, workStatement.invoice?.id)}
                  checked={isProjectChecked(projectDetails.id)}
                ></Checkbox>
              )}
              <Typography sx={styles.workStatementTitle}>
                {isClientView && ' ' + projectDetails.name + ' - '}
                <Translation id="projectDetails.billingSummary.workStatement" />
                {' ' + workStatement.statementNumber}
              </Typography>
              {!isClientView && (
                <Button variant="text" onClick={() => onClick(workStatement)}>
                  <Translation id="projectDetails.billingSummary.showDetails" />
                </Button>
              )}
            </Stack>
            {showWorkStatementSnapshot && (
              <Stack direction="row" alignItems="center" gap={1} mb={2}>
                <InfoFilledIcon color="info" sx={styles.infoIcon} />
                <Typography>
                  {formatMessage(
                    { id: AppMessages['projectDetails.billingSummary.snapshot.info.alert'] },
                    {
                      date: snapshotCreateDate,
                    },
                  )}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Box sx={styles.workStatementActions}>
            {billingCycle && (
              <WorkStatementStatusBadge
                workStatement={workStatement}
                billingCycle={billingCycle}
                isClientView={isClientView}
              />
            )}
            {billingCycle && workStatement.invoice && (
              <BillingSummaryActions invoice={workStatement.invoice} billingCycle={billingCycle} />
            )}
          </Box>
        </Stack>
      )}

      {renderWorkStatement(workStatement)}

      <WorkStatementSummary workStatement={workStatement} billingCycle={billingCycle} isClientView={isClientView} />
    </>
  );
};
