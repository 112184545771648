import { Box, Stack } from '@mui/material';
import { Tooltip, Typography } from 'lux/components';
import { Close02Icon, DeleteIcon } from 'lux/icons';
import { FieldValues, Path, useWatch } from 'react-hook-form';

import * as createProjectStyles from 'app/createProject/CreateProject.styles';
import { NeededRoleSeniority } from 'app/project/projectOverview/rateCardDetails/RateCardDetails.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSpecializations } from 'hooks/useSpecializations/useSpecializations';
import { AppMessages } from 'i18n/messages';
import { Autocomplete } from 'ui/form/autocomplete/Autocomplete';
import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import { Checkbox } from 'ui/form/checkbox/Checkbox';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { Translation } from 'ui/translation/Translation';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';
import { compareDatesAsc, parseDate } from 'utils/dateUtils';
import { formatSeniorityName } from 'utils/stringUtils';

import * as styles from './RateCardItem.styles';
import { RateCardItemProps } from './RateCardItem.types';
import { getMissingSeniorities } from './RateCardItem.utils';

export const RateCardItem = <T extends FieldValues>(props: RateCardItemProps<T>) => {
  const {
    nestedPathPrefix,
    index,
    control,
    currency,
    onRemove,
    disableDeleteButton,
    disabledDeleteButtonMessage,
    isDraft = true,
    roleName = '',
    neededRoles,
    disabled,
    inputDisabled,
  } = props;
  const pathPrefix = `${nestedPathPrefix ? `${nestedPathPrefix}.` : ''}rateCards`;

  const { formatMessage } = useLocale();
  const { data: specializations, isLoading: isSpecializationsLoading } = useSpecializations();
  const seniorityEnabled = useWatch({ name: `${pathPrefix}.${index}.seniorityEnabled` as Path<T>, control });
  const rates = control._getWatch([
    `${pathPrefix}.${index}.juniorRate`,
    `${pathPrefix}.${index}.regularRate`,
    `${pathPrefix}.${index}.seniorRate`,
  ]);

  const missingNeededSeniorities = seniorityEnabled ? getMissingSeniorities(neededRoles, rates, roleName) : [];

  const prepareWarningRoles = (roleName: string, missingSeniorities: NeededRoleSeniority[]): string | null => {
    if (!missingSeniorities || !missingSeniorities.length) {
      return null;
    }

    const translatedSeniorities =
      missingSeniorities
        .map(({ value }: NeededRoleSeniority) => `${formatSeniorityName(value)}`)
        .join(` ${formatMessage({ id: AppMessages['rateCard.form.neededRateWarning.and'] })} `) + ` ${roleName}`;

    const earliestStartDate = missingSeniorities.sort((a, b) =>
      compareDatesAsc(parseDate(a.startDate), parseDate(b.startDate)),
    )[0].startDate;

    return formatMessage(
      { id: AppMessages['rateCard.form.neededRateWarning'] },
      {
        roles: translatedSeniorities,
        startDate: earliestStartDate,
      },
    );
  };

  const warningRoles = prepareWarningRoles(roleName, missingNeededSeniorities);

  return (
    <Box sx={styles.card}>
      <Stack sx={styles.topBar} columnGap={3} direction="row" justifyContent="space-between" alignItems="flex-start">
        {isDraft && (
          <>
            <Autocomplete
              name={`${pathPrefix}.${index}.role` as Path<T>}
              control={control}
              options={mapToAutocompleteOptions(specializations || [])}
              loading={isSpecializationsLoading}
              label={formatMessage({ id: AppMessages['rateCard.form.role.label'] })}
              sx={createProjectStyles.role}
              freeSolo
              data-cy="rate-card_role"
              disabled={inputDisabled}
            />
            {onRemove && (
              <Tooltip
                arrow
                placement="top"
                title={<Translation id={'createProject.rate.rateCard.remove.disabled.tooltip'} />}
              >
                <span>
                  <SquareIconButton
                    size="small"
                    color="secondary"
                    disabled={disableDeleteButton}
                    onClick={() => onRemove(index)}
                    data-cy="rate-card_remove"
                  >
                    <Close02Icon />
                  </SquareIconButton>
                </span>
              </Tooltip>
            )}
          </>
        )}
        {!isDraft && (
          <Box sx={createProjectStyles.cardHeader}>
            <Typography variant="h6">{roleName}</Typography>

            {onRemove && (
              <Tooltip title={disableDeleteButton ? disabledDeleteButtonMessage : ''} arrow placement="right">
                <div>
                  <SquareIconButton
                    size="small"
                    color="error"
                    disabled={disableDeleteButton}
                    onClick={() => onRemove(index)}
                    data-cy="rate-card_remove"
                  >
                    <DeleteIcon />
                  </SquareIconButton>
                </div>
              </Tooltip>
            )}
          </Box>
        )}
      </Stack>

      <>
        {isDraft && (
          <Checkbox
            control={control}
            name={`${pathPrefix}.${index}.seniorityEnabled` as Path<T>}
            label="Seniority"
            data-cy="rate-card_seniority"
          />
        )}

        <Stack sx={styles.cardBody}>
          {seniorityEnabled && (
            <>
              <FormTextField
                name={`${pathPrefix}.${index}.juniorRate` as Path<T>}
                control={control}
                label={formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] })}
                startAdornment={currency}
                onChangeParser={moneyInputNumberParser}
                isDisabled={disabled?.junior}
                data-cy="rate-card_junior-rate"
              />
              <FormTextField
                name={`${pathPrefix}.${index}.regularRate` as Path<T>}
                control={control}
                label={formatMessage({ id: AppMessages['rateCard.form.midRate.label'] })}
                startAdornment={currency}
                onChangeParser={moneyInputNumberParser}
                isDisabled={disabled?.regular}
                data-cy="rate-card_mid-rate"
              />
              <FormTextField
                name={`${pathPrefix}.${index}.seniorRate` as Path<T>}
                control={control}
                label={formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] })}
                startAdornment={currency}
                onChangeParser={moneyInputNumberParser}
                isDisabled={disabled?.senior}
                data-cy="rate-card_senior-rate"
              />
            </>
          )}
          {!seniorityEnabled && (
            <FormTextField
              name={`${pathPrefix}.${index}.withoutLevelRate` as Path<T>}
              control={control}
              label={formatMessage({ id: AppMessages['rateCard.form.withoutLevelRate.label'] })}
              startAdornment={currency}
              onChangeParser={moneyInputNumberParser}
              data-cy="rate-card_without-level-rate"
            />
          )}

          {!!warningRoles && (
            <InfoBox variant="warning" sx={styles.warningBox}>
              {warningRoles}
            </InfoBox>
          )}
        </Stack>
      </>
    </Box>
  );
};
