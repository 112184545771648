import { Translation } from 'i18n/messages';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { usePeople } from 'hooks/usePeople/usePeople';
import { ActiveFilters } from 'ui/activeFilters/ActiveFilters';
import { filtersData } from 'config/data/people/filters';
import { PeopleFilters } from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext.types';
import { ActiveFilter } from 'ui/activeFilters/ActiveFilters.types';
import { createActiveFilters } from 'ui/activeFilters/createActiveFilters';
import { mapSupervisorOptions } from 'shared/people/peopleFilters/supervisorFilter/SupervisorFilter.utils';

const translationsBasedOnName: { name: string; labelTranslation: Translation }[] = [
  { name: 'fte', labelTranslation: 'filters.fte.other.value' },
  { name: 'rateMin', labelTranslation: 'people.filters.rate.min.value' },
  { name: 'rateMax', labelTranslation: 'people.filters.rate.max.value' },
  { name: 'availabilityFrom', labelTranslation: 'filters.availability.from.value' },
  { name: 'availabilityTo', labelTranslation: 'filters.availability.to.value' },
  { name: 'search', labelTranslation: 'filters.search.value' },
];

export const PeopleActiveFilters = () => {
  const { filters, toggleFilter } = usePeopleFilters();
  const { data, isLoading } = usePeople({ isSupervisor: true });

  const handleFilterDelete = ({ name, value }: ActiveFilter) => {
    toggleFilter(name as keyof PeopleFilters, value);
  };

  const supervisorFilter = mapSupervisorOptions(data);

  const showSupervisorFilter = isLoading ? 'supervisor' : undefined;

  const translationsBasedOnValue = {
    ...filtersData,
    supervisor: supervisorFilter,
  };

  const activeFiltersData = createActiveFilters(filters, {
    exclude: ['sort', 'includeUnemployed', 'nonDevRoles', showSupervisorFilter],
    translationsBasedOnValue: translationsBasedOnValue,
    translationsBasedOnName: translationsBasedOnName,
  });

  return (
    <ActiveFilters
      activeFiltersData={activeFiltersData}
      onFilterDelete={handleFilterDelete}
      dataCy="available-people_active-filters"
    />
  );
};
