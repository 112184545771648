import type { RateCardFormItem } from 'shared/project/rateCardItem/RateCardItem.types';

import type { CreateProjectForm } from './CreateProject.types';

export const defaultValues: CreateProjectForm = {
  client: {
    id: '',
    name: '',
    industries: [],
    country: '',
    accountNumber: '',
  },
  clientId: '',
  project: {
    name: '',
    description: '',
    startDate: null,
    endDate: null,
    attributes: [],
    tags: [],
    currency: '',
    timeDiff: '',
    budget: '',
    contactPerson: '',
    codename: '',
  },
  rate: {
    rateType: '',
    rateCards: [
      {
        role: '',
        juniorRate: '',
        regularRate: '',
        seniorRate: '',
        withoutLevelRate: '',
        seniorityEnabled: false,
      },
    ],
  },
};

export const internalRateCard: RateCardFormItem = {
  ...defaultValues.rate.rateCards[0],
  role: 'Internal',
  withoutLevelRate: 1,
  seniorityEnabled: false,
};
