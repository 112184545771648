import { useEffect } from 'react';

import { Stack } from '@mui/material';
import { Button, Typography } from 'lux/components';
import { AddIcon } from 'lux/icons';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Currency } from 'api/types/Currency.enum';
import { RATE_TYPES } from 'api/types/Rate.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { RateCardItem } from 'shared/project/rateCardItem/RateCardItem';
import { Select } from 'ui/form/select/Select';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { Translation } from 'ui/translation/Translation';
import { DepartmentCode, StandardDepartmentCode } from '../../../api/types/DepartmentCode.enum';
import * as styles from '../CreateProject.styles';
import { CreateProjectForm } from '../CreateProject.types';

export const RateCard = () => {
  const form = useFormContext<CreateProjectForm>();
  const { fields, append, update, remove } = useFieldArray({
    control: form.control,
    name: 'rate.rateCards',
  });

  const { formatMessage } = useLocale();

  useEffect(() => {
    const { unsubscribe } = form.watch((_, { name, type }) => {
      if (type !== 'change' || !name) {
        return;
      }

      const changedRateCard = /rate.rateCards.\d+/.exec(name);
      const isSeniorityRateChange = /(juniorRate|regularRate|seniorRate)/.test(name);

      if (!changedRateCard || !isSeniorityRateChange) {
        return;
      }

      type SeniorityRate = 'juniorRate' | 'regularRate' | 'seniorRate';

      form.trigger([
        `${changedRateCard[0]}.juniorRate`,
        `${changedRateCard[0]}.regularRate`,
        `${changedRateCard[0]}.seniorRate`,
      ] as `rate.rateCards.${number}.${SeniorityRate}`[]);
    });

    return unsubscribe;
  }, [form.watch]);

  useEffect(() => {
    update(0, {
      role: StandardDepartmentCode.PM,
      regularRate: '0',
      juniorRate: '0',
      seniorRate: '0',
      withoutLevelRate: '',
      seniorityEnabled: true,
    });
  }, []);

  return (
    <Stack sx={styles.container}>
      <Typography variant="h6" sx={styles.formHeader}>
        <Translation id="createProject.rate.header" />
      </Typography>
      <Select
        name="rate.rateType"
        control={form.control}
        options={mapToSelectOptions(Object.values(RATE_TYPES))}
        label={formatMessage({ id: AppMessages['createProject.rate.rateType'] })}
        sx={styles.formElement}
        fullWidth
        data-cy="create-rate_type"
      />
      {fields.map((item, index) => {
        const isRolePM = Boolean(item.role === DepartmentCode.pm);

        return (
          <RateCardItem
            nestedPathPrefix="rate"
            key={item.id}
            index={index}
            control={form.control}
            onRemove={remove}
            currency={form.watch('project.currency') as Currency}
            disableDeleteButton={fields.length < 2 || isRolePM}
            inputDisabled={isRolePM}
            disabledDeleteButtonMessage={formatMessage({
              id: AppMessages['createProject.rate.rateCard.tooltip.onlyOne'],
            })}
          />
        );
      })}
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        size="small"
        sx={styles.addRoleButton}
        onClick={() =>
          append({
            role: '',
            juniorRate: '',
            regularRate: '',
            seniorRate: '',
            withoutLevelRate: '',
            seniorityEnabled: true,
          })
        }
        data-cy="rate-card_add-button"
      >
        <Translation id="createProject.rate.rateCard.addRoleButton" />
      </Button>
    </Stack>
  );
};
