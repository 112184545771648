import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Avatar, Tooltip, Typography } from 'lux/components';

import { HourType } from 'api/types/HourType.enum';
import { ProjectDiscrepancyData } from 'context/projectDiscrepancy/projectDiscrepancyContext/ProjectDiscrepancyContext.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectDiscrepancy } from 'hooks/useProjectDiscrepancy/useProjectDiscrepancy';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { AppMessages } from 'i18n/messages';
import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { areDatesEqual, getStartOfMonthDate, parseDate } from 'utils/dateUtils';
import { getDisplayRateTypeUnit } from 'utils/timesheetData.utils';
import { formatWorkTime } from 'utils/workTime/workTime';
import { TableCell, TableRow, TableRowCollapsed } from '../../tableComponents/tableComponents';
import { WarningTooltip } from '../warningTooltip/WarningTooltip';
import { WorkTimeCell } from '../workTimeCell/WorkTimeCell';
import { WorkTimeSum } from '../workTimeSum/WorkTimeSum';

import * as styles from './TeamMemberRow.styles';
import { AssignmentRowProps, AssignmentWorkTimeData } from './TeamMemberRow.types';
import { AssignmentWorkTimeDetailsRow } from './teamMemberHoursDetailsRow/TeamMemberWorkTimeDetailsRow';

export const TeamMemberRow = ({ assignment }: AssignmentRowProps) => {
  const [isAssignmentRowExpanded, setIsAssignmentRowExpanded] = useState(false);
  const [assignmentWorkTimeData, setAssignmentWorkTimeData] = useState<AssignmentWorkTimeData>({
    fteWorkTimeThisMonth: 0,
    workTimeSum: 0,
    isConflict: false,
  });

  const { formatMessage } = useLocale();
  const { timesheetMonthStartDate } = useProjectTimesheet();
  const { setDiscrepancyData, discrepancyData } = useProjectDiscrepancy();
  const { isReport } = useProjectTimesheetData();

  const workTimeSumText = formatWorkTime(assignment.displayTotalWorkTime, assignment.displayRateType);

  useEffect(() => {
    setAssignmentWorkTimeData((prevValue) => ({ ...prevValue, isConflict: false }));

    if (!discrepancyData) {
      return;
    }

    const { startDate, discrepancyIdsWithWorkTime } = discrepancyData;

    const currentUserDiscrepancyData = discrepancyIdsWithWorkTime.find(
      ({ employeeId }) => employeeId === assignment.employeeId,
    );

    if (currentUserDiscrepancyData?.scheduledWorkTime === assignment.displayTotalWorkTime) {
      const updatedDiscrepancyPeopleIds = discrepancyData.discrepancyPeopleEmployeeIds.filter(
        (employeeId) => employeeId !== assignment.employeeId,
      );
      const updatedDiscrepancyIdsWithWorkTime = discrepancyIdsWithWorkTime.filter(
        ({ employeeId }) => employeeId !== assignment.employeeId,
      );
      const updatedDiscrepancyData: ProjectDiscrepancyData = {
        ...discrepancyData,
        discrepancyIdsWithWorkTime: updatedDiscrepancyIdsWithWorkTime,
        discrepancyPeopleEmployeeIds: updatedDiscrepancyPeopleIds,
      };

      setDiscrepancyData(updatedDiscrepancyPeopleIds.length === 0 ? null : updatedDiscrepancyData);
    }

    if (
      !currentUserDiscrepancyData ||
      !areDatesEqual(parseDate(startDate), getStartOfMonthDate(timesheetMonthStartDate))
    ) {
      return;
    }

    const { scheduledWorkTime } = currentUserDiscrepancyData;

    const areValuesEquals = assignment.displayTotalWorkTime === scheduledWorkTime;

    !areValuesEquals &&
      setAssignmentWorkTimeData({
        fteWorkTimeThisMonth: scheduledWorkTime,
        workTimeSum: assignment.displayTotalWorkTime,
        isConflict: true,
      });
  }, [timesheetMonthStartDate, assignment]);

  const isPersonExpanded = () => {
    if (isReport) {
      return true;
    }

    return isAssignmentRowExpanded;
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Box sx={styles.innerWrapper}>
            <Box sx={styles.assignmentInfo}>
              <Box sx={styles.userInfoWrapper}>
                <Avatar size="xs" name={assignment.name} alt={assignment.name} sx={styles.avatar} />
                <Typography sx={styles.assignmentTitle}>{assignment.name}</Typography>
                <Tooltip title={<Typography>{assignment.role}</Typography>} arrow>
                  <Typography component="span" sx={styles.assignmentRole}>
                    {assignment.role}
                  </Typography>
                </Tooltip>
              </Box>
              {assignmentWorkTimeData.isConflict && <WarningTooltip assignmentWorkTimeData={assignmentWorkTimeData} />}
            </Box>
            {assignment.hasMultipleWorkTimeEntries && !isReport && (
              <CollapseButton
                isExpanded={isAssignmentRowExpanded}
                onClick={() => setIsAssignmentRowExpanded(!isAssignmentRowExpanded)}
              />
            )}
            <WorkTimeSum>{workTimeSumText}</WorkTimeSum>
          </Box>
        </TableCell>
        {assignment.workDays.map((workDay, idx) => (
          <WorkTimeCell
            dayNumber={idx + 1}
            key={idx}
            assignmentWorkDay={workDay}
            employeeId={assignment.employeeId}
            assignmentId={workDay.assignmentId}
            rateTypeToDisplay={workDay.displayDailyRateType}
            rateTypeUnit={getDisplayRateTypeUnit(assignment.workDays, idx)}
            isRootRow
          />
        ))}
      </TableRow>

      {assignment.hasMultipleWorkTimeEntries && (
        <TableRowCollapsed isExpanded={isPersonExpanded()}>
          <>
            {assignment.rates.REGULAR.map(({ ratePercentage, rateType }) => (
              <AssignmentWorkTimeDetailsRow
                key={`${ratePercentage}-${rateType}`}
                title={formatMessage(
                  { id: AppMessages['projectDetails.timesheet.regularWorks.rate'] },
                  { rate: ratePercentage },
                )}
                assignmentWorkDays={assignment.workDays}
                employeeId={assignment.employeeId}
                hourType={HourType.regular}
                rateType={rateType}
                rateToDisplay={ratePercentage}
              />
            ))}
            {assignment.rates.OVERHOUR.map(({ ratePercentage, rateType }) => (
              <AssignmentWorkTimeDetailsRow
                key={`${ratePercentage}-${rateType}`}
                title={formatMessage(
                  { id: AppMessages['projectDetails.timesheet.overtime.rate'] },
                  { rate: ratePercentage },
                )}
                assignmentWorkDays={assignment.workDays}
                employeeId={assignment.employeeId}
                hourType={HourType.overhour}
                rateType={rateType}
                rateToDisplay={ratePercentage}
              />
            ))}
          </>
        </TableRowCollapsed>
      )}
    </>
  );
};
