import { useMemo } from 'react';

import { HourType } from 'api/types/HourType.enum';
import { RateType } from 'api/types/Rate.types';
import { TimesheetAssignmentWorkDay } from 'context/projectTimesheet/projectTimesheetDataContext/ProjectTimesheetDataContext.types';
import { useGetIsFirstDayOfProject } from 'hooks/useGetIsFirstDayOfProject/useGetIsFirstDayOfProject';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { isWeekendDate, parseDate } from 'utils/dateUtils';
import { isAbsentHourType } from 'utils/is-absent-hour-type';
import { formatMismatchWorkTime, formatWorkTime } from 'utils/workTime/workTime';

export const useWorkTimeCell = (assignmentWorkDay: TimesheetAssignmentWorkDay, dayNumber: number) => {
  const { isTimesheetEditBlocked, showTimesheetSnapshot, isReport } = useProjectTimesheetData();
  const getIsFirstDayOfProject = useGetIsFirstDayOfProject();
  const { isBeforeProjectStart, isAfterProjectEnd, isProjectStartDayMarker } = getIsFirstDayOfProject(dayNumber);

  const hasWorkTimeEntries = !!assignmentWorkDay.items.length;

  const isOutsideProjectDates = isBeforeProjectStart || isAfterProjectEnd;
  const isWeekend = isWeekendDate(parseDate(assignmentWorkDay.date));

  const isAbsent = assignmentWorkDay.items.every((workTime) => isAbsentHourType(workTime.type));
  const isDataMismatch = !!assignmentWorkDay.dataMismatch;
  const isWorkTimeAvailable = !isOutsideProjectDates && hasWorkTimeEntries;

  const isClickable = isWorkTimeAvailable && !isTimesheetEditBlocked && !showTimesheetSnapshot && !isReport;
  const showTooltip = !isWorkTimeAvailable || isTimesheetEditBlocked || isDataMismatch;

  const mismatchWorkTimeText = formatMismatchWorkTime(showTimesheetSnapshot, assignmentWorkDay.dataMismatch);

  const cellClassNames = [(isAbsent || isWeekend) && 'Absence', isWeekend && 'Weekend'].filter(Boolean).join(' ');

  const getCellDisplayValue = (workTimeSum: number, rateType?: RateType): string => {
    if (!isWorkTimeAvailable) return '';
    if (workTimeSum > 0) return formatWorkTime(workTimeSum, rateType);
    return (isAbsent && !isWeekend) || workTimeSum === 0 ? '-' : '';
  };

  const getFilteredWorkTimeSum = (
    hoursTypeToDisplay: HourType,
    rateToDisplay?: number,
    rateTypeToDisplay?: RateType,
  ) => {
    if (hoursTypeToDisplay === HourType.total) {
      return assignmentWorkDay.displayDailyWorkTime;
    }

    const filteredWorkTime = assignmentWorkDay.items.filter(
      (workTime) =>
        workTime.type === hoursTypeToDisplay &&
        workTime.ratePercentage === rateToDisplay &&
        workTime.rateType === rateTypeToDisplay,
    );

    return filteredWorkTime.reduce((sum, workTime) => sum + workTime.amount, 0);
  };

  const value = useMemo(
    () => ({
      isOutsideProjectDates,
      isWeekend,
      isAbsent,
      isClickable,
      isDataMismatch,
      isWorkTimeAvailable,
      isProjectStartDayMarker,
      cellClassNames,
      mismatchWorkTimeText,
      showTooltip,
      getCellDisplayValue,
      getFilteredWorkTimeSum,
    }),
    [
      isOutsideProjectDates,
      isWeekend,
      isAbsent,
      isClickable,
      isDataMismatch,
      isWorkTimeAvailable,
      isProjectStartDayMarker,
      cellClassNames,
      mismatchWorkTimeText,
      showTooltip,
      getCellDisplayValue,
      getFilteredWorkTimeSum,
    ],
  );

  return { ...value };
};
