import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { confirmInvoice } from 'api/actions/confirmInvoice/confirmInvoice';
import { DiscrepancyData } from 'context/projectDiscrepancy/projectDiscrepancyContext/ProjectDiscrepancyContext.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useProjectDiscrepancy } from 'hooks/useProjectDiscrepancy/useProjectDiscrepancy';
import { AppMessages } from 'i18n/messages';
import { projectsKeys } from 'utils/queryKeys';

import { UseConfirmInvoiceConflictError } from './useConfirmInvoice.types';

export const useConfirmInvoice = (invoiceId: string) => {
  const { setDiscrepancyData } = useProjectDiscrepancy();
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const { formatMessage } = useLocale();
  const { invalidateBillingSummaryCache, billingCycle, projectId } = useProjectBillingSummaryData();
  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: isConfirmingInvoice,
    error: invoiceError,
  } = useMutation(['confirmInvoice'], confirmInvoice(invoiceId), {
    onSuccess: () => {
      setIsOpenWarningModal(false);
      invalidateBillingSummaryCache();
      setDiscrepancyData(null);
      queryClient.refetchQueries(projectsKeys.extremeConfirmedInvoice(projectId));
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        const { error } = err.response?.data as UseConfirmInvoiceConflictError;

        const regex = /users(.*?)has/s;
        const cutError = error.id.match(regex);

        if (cutError && billingCycle?.startDate) {
          const parsedErrorData: DiscrepancyData[] = JSON.parse(cutError[1]);

          const employeeIdsArr = parsedErrorData.flatMap(({ employeeId }) => employeeId);

          setIsOpenWarningModal(true);
          setDiscrepancyData({
            projectId: billingCycle.projectId,
            startDate: billingCycle?.startDate,
            discrepancyPeopleEmployeeIds: employeeIdsArr,
            discrepancyIdsWithWorkTime: parsedErrorData,
          });
        }
      } else {
        enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.confirmInvoice.error'] }));
        setDiscrepancyData(null);
      }
    },
  });

  const confirmInvoiceAsPm = () => mutate({ source: 'PM' });
  const confirmInvoiceAsFinance = () => mutate({ source: 'FINANCE' });
  const forceConfirm = () => mutate({ source: 'PM', force: true });

  return {
    confirmInvoiceAsPm,
    confirmInvoiceAsFinance,
    forceConfirm,
    isConfirmingInvoice,
    invoiceError,
    isOpenWarningModal,
    setIsOpenWarningModal,
  };
};
