import { Box } from '@mui/material';
import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { WarningTooltipProps } from '../WarningTooltip.types';

import * as styles from './WarningTooltipContent.styles';

export const WarningTooltipContent = ({ assignmentWorkTimeData }: WarningTooltipProps) => {
  const { t } = useLocale();

  return (
    <Box sx={styles.messageContainer}>
      <Typography sx={styles.title}>{t('projectDetails.timesheet.tooltip.warning.title')}</Typography>
      <Box sx={styles.workTimeWrapper}>
        <Typography>{t('projectDetails.timesheet.tooltip.warning.planedWorkTime')}</Typography>
        <Typography>{assignmentWorkTimeData.fteWorkTimeThisMonth}</Typography>
      </Box>
      <Box sx={styles.workTimeWrapper}>
        <Typography>{t('projectDetails.timesheet.tooltip.warning.recordedWorkTime')}</Typography>
        <Typography>{assignmentWorkTimeData.workTimeSum}</Typography>
      </Box>
    </Box>
  );
};
