import type { Rows } from 'lux/components/organisms/table/Table.types';

import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { Seniority } from 'api/types/Seniority.enum';

export const getRows = (assignments: Assignment[]): Rows =>
  assignments.map(({ person, role, id }) => ({
    name: `${person.employeeId}-${role}-${id}`,
    assignmentId: id,
  }));

export const isLowerSeniority = (
  employeeSeniority?: Seniority | null,
  assignedSeniority?: Seniority | null,
): boolean => {
  if (
    !employeeSeniority ||
    !assignedSeniority ||
    employeeSeniority === Seniority.withoutLevel ||
    assignedSeniority === Seniority.withoutLevel
  ) {
    return false;
  }

  const seniorityOrder: Partial<Record<Seniority, number>> = {
    [Seniority.junior]: 1,
    [Seniority.mid]: 2,
    [Seniority.senior]: 3,
  };

  const employeeSeniorityRank = seniorityOrder[employeeSeniority] ?? 0;
  const assignedSeniorityRank = seniorityOrder[assignedSeniority] ?? 0;

  return employeeSeniorityRank > assignedSeniorityRank;
};
