import type { Styles } from 'styles/theme';
import { HEADER_HEIGHT } from 'ui/header/Header.styles';
import { FOOTER_HEIGHT } from '../footer/Footer.styles';

export const wrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: `calc(100vh - ${FOOTER_HEIGHT}px - ${HEADER_HEIGHT}px)`,
  flexDirection: 'column',
};

export const title: Styles = {
  marginTop: 4,
  marginBottom: 2,
};
