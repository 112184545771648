import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from 'lux/components';
import { useFormContext } from 'react-hook-form';

import { Autocomplete } from 'ui/form/autocomplete/Autocomplete';
import { MultipleAutocomplete } from 'ui/form/autocomplete/MultipleAutocomplete';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useIndustries } from 'hooks/useIndustries/useIndustries';
import { countriesList } from 'config/data/countries';
import { useAllClients } from 'hooks/useAllClients/useAllClients';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import type { Aliases } from 'ui/form/autocomplete/Autocomplete.types';
import * as styles from '../CreateProject.styles';

export const ClientDetails = () => {
  const form = useFormContext();
  const { formatMessage } = useLocale();
  const [isExistingClient, setIsExistingClient] = useState<boolean>(false);
  const { data, isLoading: isClientsLoading } = useAllClients();
  const { data: industries = [] } = useIndustries();

  const clients = data?.clients ?? [];
  const clientsNames = mapToAutocompleteOptions(clients.map((client) => client.name));
  const name = form.watch('client.name');
  const nameAliases: Aliases = [['tsh', 'The Software House']];
  const countryAliases: Aliases = [
    ['usa', 'United States'],
    ['uk', 'United Kingdom'],
    ['uae', 'United Arab Emirates'],
  ];

  useEffect(() => {
    const foundClient = clients.find((client) => client.name === name);

    form.clearErrors();

    if (foundClient) {
      setIsExistingClient(true);
      form.setValue('clientId', foundClient.id);
      form.setValue('client.industries', foundClient.industries);
      form.setValue('client.country', foundClient.country);
      form.setValue('client.accountNumber', foundClient.accountNumber || '');
    } else {
      setIsExistingClient(false);
      form.setValue('clientId', '');
    }

    if (!name) {
      form.setValue('client.industries', []);
      form.setValue('client.country', '');
      form.setValue('client.accountNumber', '');
    }
  }, [name]);

  return (
    <Box>
      <Typography variant="h6" sx={styles.formHeader}>
        {formatMessage({ id: AppMessages['createProject.client.header'] })}
      </Typography>
      <Autocomplete
        name="client.name"
        control={form.control}
        label={formatMessage({ id: AppMessages['createProject.client.form.name'] })}
        loading={isClientsLoading}
        options={clientsNames}
        sx={styles.formElement}
        freeSolo
        aliases={nameAliases}
        data-cy="create-project_client-name"
      />
      <MultipleAutocomplete
        name="client.industries"
        control={form.control}
        label={formatMessage({ id: AppMessages['createProject.client.form.industry'] })}
        options={mapToAutocompleteOptions(industries)}
        sx={styles.formElement}
        disabled={!name || isExistingClient}
        freeSolo
        dataCy="create-project_industry"
      />
      <Autocomplete
        name="client.country"
        control={form.control}
        label={formatMessage({ id: AppMessages['createProject.client.form.country'] })}
        options={mapToAutocompleteOptions(countriesList)}
        sx={styles.formElement}
        disabled={!name || isExistingClient}
        aliases={countryAliases}
        data-cy="create-project_country"
      />
      <FormTextField
        name="client.accountNumber"
        control={form.control}
        label={formatMessage({ id: AppMessages['createProject.client.form.account'] })}
        sx={styles.formElement}
        isDisabled={!name || isExistingClient}
        fullWidth
        data-cy="create-project_account-number"
      />
    </Box>
  );
};
