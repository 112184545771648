import { Table } from 'lux/components';

import { useAssignmentsFilters } from 'hooks/useAssignmentsFilters/useAssignmentsFilters';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { parseSortFilter, stringifySortFilter } from 'utils/filters/filters';
import { SortOrder } from 'utils/filters/filters.types';
import { AssignmentsSortingField } from 'context/assignmentsFilters/assignmentsFiltersContext/AssignmentsFiltersContext.types';
import { Translation } from 'ui/translation/Translation';

import * as styles from './AssignmentsTableHead.styles';
import { AssignmentsTableHeadProps } from './AssignmentsTableHead.types';

export const AssignmentsTableHead = (props: AssignmentsTableHeadProps) => {
  const { rows, selected, onSelectAll } = props;
  const { setFilterValue, filters } = useAssignmentsFilters();
  const { field: currentField, order } = parseSortFilter(filters.sort);

  const getDirection = (field: AssignmentsSortingField) => {
    if (currentField !== field) {
      return undefined;
    }
    return order.toLowerCase() as Lowercase<SortOrder>;
  };

  const handleLabelClick = (field: AssignmentsSortingField) => {
    const newOrder = currentField === field && order === SortOrder.asc ? SortOrder.desc : SortOrder.asc;

    setFilterValue('sort', stringifySortFilter(field, newOrder));
  };

  const {
    projectDetails: { plannedExtension, assignments },
  } = useProjectDetails();

  const isPlannedMemberExtension = assignments.some((assignment) => assignment.extensionDate);

  return (
    <Table.Head sx={styles.tableHead}>
      <Table.Row>
        <Table.Cell padding="checkbox">
          {props.isSelectAllOption && (
            <Table.HeadCheckbox
              rows={rows}
              selected={selected}
              onClick={onSelectAll}
              disabled={props.isHeadBoxDisabled}
            />
          )}
        </Table.Cell>
        <Table.Cell sx={styles.labelCell}>
          <Table.SortLabel disabled>
            <Translation id="projectDetails.teamDetails.assigned.table.employee" />
          </Table.SortLabel>
        </Table.Cell>
        <Table.Cell width={85} sx={styles.labelCell}>
          <Table.SortLabel disabled>
            <Translation id="projectDetails.teamDetails.assigned.table.fte" />
          </Table.SortLabel>
        </Table.Cell>
        <Table.Cell width={200} sx={styles.labelCell}>
          <Table.SortLabel
            active={currentField === AssignmentsSortingField.role}
            direction={getDirection(AssignmentsSortingField.role)}
            onClick={() => {
              handleLabelClick(AssignmentsSortingField.role);
            }}
          >
            <Translation id="projectDetails.teamDetails.assigned.table.role" />
          </Table.SortLabel>
        </Table.Cell>
        <Table.Cell width={130} sx={styles.labelCell}>
          <Table.SortLabel
            active={currentField === AssignmentsSortingField.startDate}
            direction={getDirection(AssignmentsSortingField.startDate)}
            onClick={() => {
              handleLabelClick(AssignmentsSortingField.startDate);
            }}
          >
            <Translation id="projectDetails.teamDetails.assigned.table.startDate" />
          </Table.SortLabel>
        </Table.Cell>
        <Table.Cell width={130} sx={styles.labelCell}>
          <Table.SortLabel
            active={currentField === AssignmentsSortingField.endDate}
            direction={getDirection(AssignmentsSortingField.endDate)}
            onClick={() => {
              handleLabelClick(AssignmentsSortingField.endDate);
            }}
          >
            <Translation id="projectDetails.teamDetails.assigned.table.endDate" />
          </Table.SortLabel>
        </Table.Cell>
        {(plannedExtension || isPlannedMemberExtension) && (
          <Table.Cell width={130} sx={styles.labelCell}>
            <Table.SortLabel disabled>
              <Translation id="projectDetails.teamDetails.assigned.table.extensionDate" />
            </Table.SortLabel>
          </Table.Cell>
        )}
        <Table.Cell width={111}></Table.Cell>
        <Table.Cell width={44}></Table.Cell>
      </Table.Row>
    </Table.Head>
  );
};
