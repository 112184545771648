import { joiResolver } from '@hookform/resolvers/joi';
import Joi, { CustomHelpers, ObjectSchema } from 'joi';
import { useForm } from 'react-hook-form';

import { RATE_TYPES, RateType, WORK_DAY_LENGTH } from 'api/types/Rate.types';

import { SchemaContext, WorkTimePopoverForm } from './WorkTimePopover.types';

const WorkTimePopoverSchema: ObjectSchema<WorkTimePopoverForm> = Joi.object<WorkTimePopoverForm>()
  .keys({
    regularWorkAmount: Joi.number().allow('').min(0).max(24).required(),
    regularWorkRate: Joi.number().min(0).required(),
    overTimeAmount: Joi.number().allow('').min(0).max(24).required(),
    overTimeRate: Joi.number().min(0).required(),
  })
  .custom((form: WorkTimePopoverForm, helpers: CustomHelpers<SchemaContext>) => {
    const rateType = helpers.prefs.context?.['rateType'];
    const workTimeSum = form.regularWorkAmount + form.overTimeAmount;
    const maxHoursPerDay = 24;
    const maxValue = rateType === RATE_TYPES.daily ? maxHoursPerDay / WORK_DAY_LENGTH : maxHoursPerDay;

    if (workTimeSum > maxValue) {
      return helpers.error('regularWorkAmount');
    }

    return form;
  }, 'customValidationStr');

export const useWorkTimePopoverForm = (defaultValues: WorkTimePopoverForm, rateType: RateType) => {
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { isValid },
  } = useForm<WorkTimePopoverForm>({
    defaultValues,
    criteriaMode: 'all',
    resolver: joiResolver(WorkTimePopoverSchema, {
      context: { rateType },
    }),
  });

  return { control, reset, handleSubmit, isValid, watch, setError, clearErrors, trigger };
};
