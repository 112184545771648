import { Table } from 'lux/components';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { PeopleSortingField } from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext.types';
import { parseSortFilter, stringifySortFilter } from 'utils/filters/filters';
import { SortOrder } from 'utils/filters/filters.types';

import * as styles from './PeopleTableHead.styles';

export const PeopleTableHead = () => {
  const { formatMessage } = useLocale();
  const { setFilterValue, filters } = usePeopleFilters();

  const { field: currentField, order } = parseSortFilter(filters.sort);

  const getDirection = (field: PeopleSortingField) => {
    if (currentField !== field) {
      return undefined;
    }
    return order.toLowerCase() as Lowercase<SortOrder>;
  };

  const handleLabelClick = (field: PeopleSortingField) => {
    const newOrder = currentField === field && order === SortOrder.asc ? SortOrder.desc : SortOrder.asc;

    setFilterValue('sort', stringifySortFilter(field, newOrder));
  };

  return (
    <Table.Row component="div">
      <Table.Cell sx={{ ...styles.tableHeadCell, width: 220 }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === PeopleSortingField.name}
          direction={getDirection(PeopleSortingField.name)}
          onClick={() => {
            handleLabelClick(PeopleSortingField.name);
          }}
        >
          {formatMessage({ id: AppMessages['people.peopleList.tableHead.employee'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: 100 }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === PeopleSortingField.seniority}
          direction={getDirection(PeopleSortingField.seniority)}
          onClick={() => {
            handleLabelClick(PeopleSortingField.seniority);
          }}
        >
          {formatMessage({ id: AppMessages['people.peopleList.tableHead.seniority'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: 120 }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === PeopleSortingField.department}
          direction={getDirection(PeopleSortingField.department)}
          onClick={() => {
            handleLabelClick(PeopleSortingField.department);
          }}
        >
          {formatMessage({ id: AppMessages['people.peopleList.tableHead.department'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: 150 }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === PeopleSortingField.project}
          direction={getDirection(PeopleSortingField.project)}
          disabled={true}
        >
          {formatMessage({ id: AppMessages['people.peopleList.tableHead.currentProject'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, paddingX: 1, width: 120 }} component="div" padding="none">
        {formatMessage({ id: AppMessages['people.peopleList.tableHead.supervizor'] })}
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: 140 }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === PeopleSortingField.availability}
          direction={getDirection(PeopleSortingField.availability)}
          onClick={() => {
            handleLabelClick(PeopleSortingField.availability);
          }}
        >
          {formatMessage({ id: AppMessages['people.peopleList.tableHead.status'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: 60 }} component="div" padding="none"></Table.Cell>
    </Table.Row>
  );
};
