import { stringify, parse } from 'qs';
import isEqual from 'lodash/isEqual';
import type { IStringifyOptions, IParseOptions } from 'qs';

const STRINGIFY_DEFAULT_OPTIONS: IStringifyOptions = {
  encode: true,
  arrayFormat: 'brackets',
  addQueryPrefix: true,
};

const PARSE_DEFAULT_OPTIONS: IParseOptions = {
  ignoreQueryPrefix: true,
};

export const stringifyQueryParams = <T = Record<string, unknown>>(
  payload: T,
  options = STRINGIFY_DEFAULT_OPTIONS,
): string => stringify(payload, options);

export const filterEmptyQueryParams = (
  payload: Record<string, unknown>,
  defaultFilters: Record<string, unknown>,
): Record<string, unknown> => {
  const newPayload: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(payload as Record<string, unknown>)) {
    if (isEqual(value, defaultFilters[key])) {
      continue;
    }
    newPayload[key] = value;
  }

  return newPayload;
};

export const parseQueryString = <T = Record<string, unknown>>(
  queryString: string,
  options = PARSE_DEFAULT_OPTIONS,
): T => parse(queryString, options) as T;
