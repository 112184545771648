import * as React from 'react';
import { HTMLAttributes, useMemo } from 'react';

import { AutocompleteRenderGetTagProps, AutocompleteRenderOptionState } from '@mui/material';
import { AutocompleteGeneric, Chip, MenuItem } from 'lux/components';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { usePeople } from 'hooks/usePeople/usePeople';
import { AppMessages } from 'i18n/messages';

import { mapSupervisorOptions } from './SupervisorFilter.utils';
import type { Option, SupervisorFilterTypes } from './SupervisorFilter.types';

const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: Option, state: AutocompleteRenderOptionState) => (
  <MenuItem {...props} selected={state.selected}>
    {option.label}
  </MenuItem>
);

const renderTags = (value: Option[], getTagProps: AutocompleteRenderGetTagProps) =>
  value.map((tag, index) => {
    const { key, ...rest } = getTagProps({ index });
    return (
      <Chip
        {...rest}
        key={key}
        label={tag.label}
        sx={{
          '&.MuiChip-root': {
            margin: 0.5,
          },
        }}
      />
    );
  });

export const SupervisorFilter = ({ variant }: SupervisorFilterTypes) => {
  const { formatMessage } = useLocale();
  const {
    filters: { supervisor },
    setFilterValue,
  } = usePeopleFilters();
  const { data, isLoading } = usePeople({ isSupervisor: true });

  const handleFilterChange = (e: React.SyntheticEvent, values: Array<Option>) => {
    const newValues = values.map((option) => option.value);
    setFilterValue('supervisor', newValues);
  };

  const options = useMemo(() => mapSupervisorOptions(data), [data]);
  const selectedValues = useMemo(
    () => options.filter((option) => supervisor.includes(option.value)),
    [options, supervisor],
  );

  return (
    <Filter variant={variant} label={formatMessage({ id: AppMessages['people.filters.supervisor.label'] })}>
      <AutocompleteGeneric
        onChange={handleFilterChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        multiple
        fullWidth
        size="small"
        options={options}
        value={selectedValues}
        getOptionLabel={(option) => option.label}
        renderOption={renderOption}
        renderTags={renderTags}
        disabled={isLoading}
      />
    </Filter>
  );
};
