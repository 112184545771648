import { Styles } from 'styles/theme';

export const rateCardItemsWrapper: Styles = {
  display: 'grid',
  gridTemplateAreas: "'rateTypeLabel rateType' 'dateRange roles' 'items items' 'children children'",
  gridTemplateColumns: '1fr 160px',
  rowGap: 3,
  marginTop: 3,
};

export const dateRange: Styles = {
  gridArea: 'dateRange',
  fontSize: 14,
  lineHeight: '18px',
  fontWeight: 500,
};

export const roles: Styles = {
  gridArea: 'roles',
  display: 'inline-grid',
  minWidth: 150,
  gridTemplateColumns: '36px 8px 36px 8px 36px',
  fontSize: 12,
  lineHeight: '20px',
  color: 'text.secondary',
  textAlign: 'center',
};

export const rateTypeLabel: Styles = {
  gridArea: 'rateTypeLabel',
  fontSize: 14,
  lineHeight: '18px',
  fontWeight: 500,
};

export const rateType: Styles = {
  gridArea: 'rateType',
  fontSize: 12,
  lineHeight: '18px',
  color: 'text.secondary',
};

export const items: Styles = {
  gridArea: 'items',
  display: 'grid',
  gap: 2,
};

export const children: Styles = {
  gridArea: 'children',
};
