import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';

import { ActionsValue, permissions } from './Permissions';

export const usePermission = () => {
  const { data: user } = useCurrentUser();

  const hasPermission = (action: ActionsValue) => {
    if (!user?.metadata?.accessType) return false;

    return permissions?.[user.metadata.accessType]?.includes(action) || false;
  };

  return { hasPermission };
};
