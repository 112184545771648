import { Styles } from 'styles/theme';

export const tableHeadCell: Styles = {
  typography: 'caption',
  fontWeight: 600,
  color: 'text.secondary',
  paddingX: 0,
  whiteSpace: 'nowrap',
  backgroundColor: (theme) => theme.palette.surface.default,

  '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
    opacity: 1,
  },
};
