import { PublicClientApplication } from '@azure/msal-browser';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

import { setMaintenanceMode } from 'utils/maintenanceMode';
import { MSAL_CONFIGURATION } from '../../../config/msal/msalConfig';

export const responseSuccessInterceptor = (response: AxiosResponse) => {
  const isMaintenanceMode = response.data?.isMaintenanceMode ?? false;
  setMaintenanceMode(isMaintenanceMode);
  return response;
};

const msalInstance = new PublicClientApplication(MSAL_CONFIGURATION);

export const responseFailureInterceptor = async (
  error: AxiosError,
  {
    onRefreshTokenSuccess,
    onRefreshTokenError,
  }: {
    onRefreshTokenSuccess: (token: { accessToken: string; expires: number; refreshToken: string }) => void;
    onRefreshTokenError: VoidFunction;
  },
) => {
  await msalInstance.initialize();
  const originalRequest = error.config;
  if (error.response?.status === 401 && originalRequest._retry) {
    onRefreshTokenError();
    return Promise.reject(error);
  }

  if (error.response?.status === 401) {
    originalRequest._retry = true;
    const allAccounts = msalInstance.getAllAccounts();
    const account = allAccounts[0];
    if (!account) {
      throw new Error('Azure account is null');
    }
    try {
      const getTokenOptions = {
        scopes: [`${process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID}/.default`],
        account,
      };
      const authRes = await msalInstance.acquireTokenSilent(getTokenOptions);
      onRefreshTokenSuccess({
        accessToken: authRes.accessToken,
        refreshToken: authRes.accessToken,
        expires: authRes.expiresOn?.getTime() ?? 0,
      });
      return axios(originalRequest);
    } catch {
      const getTokenOptions = {
        scopes: [`${process.env.ACTIVE_DIRECTORY_CLIENT_ID}/.default`],
        account,
      };
      msalInstance.acquireTokenRedirect(getTokenOptions);
      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
};
