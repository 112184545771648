import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const steps = () => {
  const { formatMessage } = intl.currentIntl;

  return [
    {
      id: 'client',
      label: formatMessage({ id: AppMessages['createProject.client.stepper'] }),
    },
    {
      id: 'project',
      label: formatMessage({ id: AppMessages['createProject.project.stepper'] }),
    },
    {
      id: 'rate-card',
      label: formatMessage({ id: AppMessages['createProject.rate.rateCard.stepper'] }),
    },
  ];
};
