import { Typography } from '@mui/material';
import { Button } from 'lux/components';
import { NavLink } from 'react-router-dom';

import { WorkStatementAssignment } from 'api/types/BillingSummary.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectDiscrepancy } from 'hooks/useProjectDiscrepancy/useProjectDiscrepancy';
import { ClientDetailsRoute, ProjectDetailsRoute } from 'routing/AppRoute.enum';

import * as styles from './WarningMessage.styles';
import { WarningMessageProps, isTimesheetTeamMember, isWorkStatementTeamMember } from './WarningMessage.types';

export const getAssignments = (
  billingSummaryAssignments: (WorkStatementAssignment | undefined)[] | undefined,
): WorkStatementAssignment[] => {
  if (billingSummaryAssignments) {
    const filteredAssignments = billingSummaryAssignments.filter(
      (people): people is WorkStatementAssignment => people !== undefined,
    );

    if (filteredAssignments.length === billingSummaryAssignments.length) {
      return filteredAssignments;
    }
  }
  return [];
};

export const WarningMessage = ({ assignments, isClientView = false }: WarningMessageProps) => {
  const { t } = useLocale();
  const firstPartOfWarning = t('projectDetails.billingSummary.warning.discrepancyWorkTime.message.one');
  const secondPartOfWarning = t('projectDetails.billingSummary.warning.discrepancyWorkTime.message.two');
  const { discrepancyData } = useProjectDiscrepancy();

  const discrepancyPeopleIds = discrepancyData?.discrepancyPeopleEmployeeIds ?? [];

  const getMessage = () => {
    if (assignments) {
      const filteredDiscrepancyPeople = assignments.filter((assignment) =>
        discrepancyPeopleIds.some((employeeId) => employeeId === assignment.employeeId),
      );

      if (isTimesheetTeamMember(filteredDiscrepancyPeople)) {
        return filteredDiscrepancyPeople.flatMap((person) => `${person?.name}`).join(' and ');
      }

      if (isWorkStatementTeamMember(filteredDiscrepancyPeople)) {
        return filteredDiscrepancyPeople.flatMap((person) => `${person?.firstName} ${person?.lastName}`).join(' and ');
      }
    }
  };

  return (
    <Typography sx={styles.message}>
      {firstPartOfWarning}
      <Button
        variant="text"
        sx={styles.message}
        to={`../${isClientView ? ClientDetailsRoute.timesheets : ProjectDetailsRoute.timesheet}`}
        component={NavLink}
        end
      >
        {getMessage()}
      </Button>
      {secondPartOfWarning}
    </Typography>
  );
};
