import { Box } from '@mui/material';
import { Tooltip } from 'lux/components';
import { WarningFilledIcon } from 'lux/icons';

import * as styles from './WarningTooltip.styles';
import { WarningTooltipProps } from './WarningTooltip.types';
import { WarningTooltipContent } from './warningTooltipContent/WarningTooltipContent';

export const WarningTooltip = ({ assignmentWorkTimeData }: WarningTooltipProps) => (
  <Tooltip
    placement="top"
    arrow
    title={<WarningTooltipContent assignmentWorkTimeData={assignmentWorkTimeData} />}
    PopperProps={{ sx: styles.popper }}
  >
    <Box sx={styles.iconWrapper}>
      <WarningFilledIcon color="warning" />
    </Box>
  </Tooltip>
);
