import { Currency } from 'api/types/Currency.enum';
import { NeedDto } from 'api/types/Need.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { RateType } from 'api/types/Rate.types';
import { Seniority } from 'api/types/Seniority.enum';
import { Person } from '../getPerson/getPerson.types';
import { Assignment } from '../getPersonAssignments/getPersonAssignments.types';

export type GetProjectResponse = ProjectDetails;

export type ProjectDetails = Omit<Project, 'rateCards'> & {
  currentRateCard: RateCard;
  futureRateCard: RateCard | null;
  previousRateCards: RateCard[];
};

export type GetProjectParams = null;

export type Client = {
  id: string;
  name: string;
  country: string;
  industries: string[];
  accountNumber?: string;
};

export type RateCardItem = {
  amount: number;
  seniority: string;
  specialization: string;
};

export type RateCard = {
  type: RateType;
  items: MappedRateCard;
  currency: string;
  appliesFrom: string;
  appliesUntil: string;
};

export type MappedRateCardItem = {
  seniority: Seniority;
  amount: number;
};

export type MappedRateCard = {
  [key: string]: MappedRateCardItem[];
};

export type Link = {
  name: string;
  url: string;
};

export type KeyRole = {
  id: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  people: Person[];
};

export enum ProjectSource {
  lux = 'LUX',
  hubspot = 'HUBSPOT',
}

export type Project = {
  id: string;
  name: string;
  client: Client;
  startDate: string;
  endDate: string;
  extensionDate: string | null;
  plannedExtension: boolean;
  assignments: Assignment[];
  archivingDate: string;
  description: string;
  stage: ProjectStage;
  tags: string[];
  attributes: string[];
  category: string;
  teams: string[];
  actualHeadCount: number;
  targetHeadCount: number;
  createdAt: string;
  updatedAt: string;
  rateCards: RateCard[];
  owner: string;
  needCount: number;
  needs?: NeedDto[];
  timeDifference?: string;
  budget?: string;
  contactPerson?: string;
  codename?: string;
  slackChannelId: string;
  links?: Link[];
  currency: Currency;
  closingReason: string;
  activeAssignments: number;
  assignedPmCount: number;
  keyRoles: KeyRole[];
  projectSource: ProjectSource;
};
