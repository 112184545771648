import { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { Alert, Button, Emoji, Typography } from 'lux/components';
import { AddIcon, DownloadIcon } from 'lux/icons';
import { NavLink } from 'react-router-dom';

import { invoiceDataActions } from 'api/actions/issueInvoice/issueInvoice.types';
import { WorkStatement } from 'api/types/BillingSummary.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectBillingSummary } from 'hooks/useProjectBillingSummary/useProjectBillingSummary';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useProjectDiscrepancy } from 'hooks/useProjectDiscrepancy/useProjectDiscrepancy';
import { ClientDetailsRoute, ProjectDetailsRoute } from 'routing/AppRoute.enum';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';
import { ProjectNavigationBar } from 'ui/projectNavigationBar/ProjectNavigationBar';
import { Translation } from 'ui/translation/Translation';
import { parseDate } from 'utils/dateUtils';
import { LastUpdate } from '../lastUpdate/LastUpdate';

import { IssueInvoiceAction } from './billingSummaryActions/issueInvoiceAction/IssueInvoiceAction';
import { BillingSummaryEmptyState } from './billingSummaryEmptyState/BillingSummaryEmptyState';
import { BillingSummaryFilters } from './billingSummaryFilters/BillingSummaryFilters';
import { BillingSummaryWorkStatement } from './billingSummaryWorkStatement/BillingSummaryWorkStatement';
import { BillingSummaryWorkStatementSkeleton } from './billingSummaryWorkStatement/BillingSummaryWorkStatement.skeleton';
import { DiscrepancyWorkTimeWarning } from './discrepancyWorkTimeWarning/DiscrepancyWorkTimeWarning';
import { getAssignments, WarningMessage } from './discrepancyWorkTimeWarning/warningMessage/WarningMessage';
import { InvoiceErrorBanner } from './invoiceErrorBanner/InvoiceErrorBanner';
import * as styles from './ProjectBillingSummary.styles';
import { ProjectBillingSummaryProps } from './ProjectBillingSummary.types';
import { WorkStatementDetailsSideCard } from './workStatementDetailsSideCard/WorkStatementDetailsSideCard';
import { AddWorkStatementModal } from './workStatementModals/addWorkStatementModal/AddWorkStatementModal';
import { EditWorkStatementModal } from './workStatementModals/editWorkStatementModal/EditWorkStatementModal';

export const ProjectBillingSummary = ({
  isClientView = false,
  bINumber,
  groupByAllBI,
  setGroupByAllBI,
  onCheckboxChange,
  invoiceData,
  invoiceDataAction,
  issueInvoiceMode,
}: ProjectBillingSummaryProps) => {
  const [selectedWorkStatement, setSelectedWorkStatement] = useState<WorkStatement | null>(null);
  const [isWorkStatementDetailsSideCardOpen, setIsWorkStatementDetailsSideCardOpen] = useState(false);

  const { projectDetails } = useProjectDetails();
  const { hasBillingCycleDiscrepancy } = useProjectDiscrepancy();
  const { groupBy, setGroupBy } = useProjectBillingSummary();
  const {
    isLoadingBillingSummaryData,
    workStatements,
    showWorkStatementSnapshot,
    dataMismatch,
    billingCycle,
    selectedDate,
    lastManualUpdate,
    goToPreviousMonth,
    goToNextMonth,
    goToCurrentMonth,
    billingSummaryAssignments,
    billingSummaryError,
    confirmInvoiceError,
  } = useProjectBillingSummaryData();
  const { id, endDate, startDate, stage } = projectDetails;
  const { t } = useLocale();

  const invoiceErrorTitle = `${t('projectDetails.invoice.action.error.title.first.part')} ${t(
    'projectDetails.invoice.action.error.title.second.part',
  )}`;

  const isBillingCycleNotGenerated =
    [ProjectStage.lead, ProjectStage.archived].includes(stage) || isLoadingBillingSummaryData;
  const displayLastUpdateCondition = lastManualUpdate?.updatedAt && lastManualUpdate?.username;
  const isMissingWorkStatements = workStatements.length === 0;
  const showDiscrepancyWarning = hasBillingCycleDiscrepancy(
    projectDetails.id,
    parseDate(billingCycle?.startDate || ''),
  );
  const showMismatchDataError = dataMismatch?.subTotal || dataMismatch?.total;
  const reportRoute = `../${ProjectDetailsRoute.report}?date=${selectedDate.toISOString()}`;

  const showTsNav = bINumber === 0 || !isClientView;

  const handleSetSelectedWorkStatement = (workStatement: WorkStatement) => {
    setSelectedWorkStatement(workStatement);
    setIsWorkStatementDetailsSideCardOpen(true);
  };

  const {
    isOpen: isAddWorkStatementModalOpen,
    setOpen: setAddWorkStatementModalOpen,
    setClose: setAddWorkStatementModalClose,
  } = useDialog();
  const {
    isOpen: isEditWorkStatementModalOpen,
    setOpen: setEditWorkStatementModalOpen,
    setClose: setEditWorkStatementModalClose,
  } = useDialog();

  useEffect(() => {
    if (setSelectedWorkStatement) {
      setSelectedWorkStatement(
        workStatements.find((workStatement) => workStatement.id === selectedWorkStatement?.id) ||
          workStatements?.[0] ||
          null,
      );
    }
  }, [workStatements]);

  useEffect(() => {
    if (confirmInvoiceError.message) {
      window.scrollTo(0, 0);
    }
  }, [confirmInvoiceError]);

  useEffect(() => {
    const firstBiProject = 0;

    if (bINumber === firstBiProject && isClientView && setGroupByAllBI) {
      setGroupByAllBI(groupBy);
    }

    if (bINumber !== firstBiProject && isClientView && groupByAllBI) {
      setGroupBy(groupByAllBI);
    }
  }, [groupBy, groupByAllBI]);

  const enableAddNewOrder = [ProjectStage.lead, ProjectStage.ongoing, ProjectStage.closing].includes(stage);

  const handleClose = () => setIsWorkStatementDetailsSideCardOpen(false);

  const handleGoNextMoth = () => {
    invoiceDataAction?.(invoiceDataActions.clear);
    goToNextMonth();
  };
  const handleGoPreviosMoth = () => {
    invoiceDataAction?.(invoiceDataActions.clear);
    goToPreviousMonth();
  };
  const handleGoCurrentMoth = () => {
    invoiceDataAction?.(invoiceDataActions.clear);
    goToCurrentMonth();
  };

  return (
    <Stack sx={styles.container} direction="row" spacing={3}>
      <Box sx={styles.wrapper(isWorkStatementDetailsSideCardOpen)}>
        <CardWrapper shouldShow={isClientView}>
          <Stack spacing={4} width="100%" flex={1} position="relative">
            <Box>
              {showDiscrepancyWarning && (
                <DiscrepancyWorkTimeWarning
                  to={isClientView ? `../${ClientDetailsRoute.timesheets}` : ProjectDetailsRoute.timesheet}
                  message={
                    <WarningMessage
                      assignments={getAssignments(billingSummaryAssignments)}
                      isClientView={isClientView}
                    />
                  }
                  modifiedText
                />
              )}
              {confirmInvoiceError.message && (
                <InvoiceErrorBanner title={invoiceErrorTitle} message={confirmInvoiceError.message} />
              )}
              {showMismatchDataError && (
                <Alert
                  severity="error"
                  icon={<Emoji type="money" />}
                  title={t('projectDetails.billingSummary.mismatchError.title')}
                  description={t('projectDetails.billingSummary.mismatchError.description')}
                  sx={styles.moneyEmoji}
                />
              )}

              <Stack width={'100%'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                {!isClientView && (
                  <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    component={NavLink}
                    to={reportRoute}
                    startIcon={<DownloadIcon />}
                    end
                  >
                    <Translation id="projectDetails.billingSummary.report" />
                  </Button>
                )}
                {displayLastUpdateCondition && !isClientView && (
                  <LastUpdate updatedAt={lastManualUpdate?.updatedAt} username={lastManualUpdate?.username} />
                )}
              </Stack>
              {showTsNav && (
                <>
                  <Stack direction="row" mt={3} flex={1} justifyContent={'space-between'}>
                    <Typography sx={styles.title}>
                      <Translation id="projectDetails.billingSummary" />
                    </Typography>
                    {process.env.REACT_APP_MVP2_FEATURE_ENABLED === 'true' && enableAddNewOrder && (
                      <Button
                        onClick={setAddWorkStatementModalOpen}
                        size="medium"
                        variant="outlined"
                        color="primary"
                        disabled={showWorkStatementSnapshot}
                        startIcon={<AddIcon />}
                      >
                        <Translation id="projectDetails.billingSummary.addNewOrder" />
                      </Button>
                    )}
                  </Stack>
                  <Box display="flex" justifyContent="space-between">
                    <ProjectNavigationBar
                      selectedMonthStartDate={selectedDate}
                      onPreviousMonthClick={handleGoPreviosMoth}
                      onNextMonthClick={handleGoNextMoth}
                      onCurrentMonthClick={handleGoCurrentMoth}
                      isNavNextArrowDisabled={isBillingCycleNotGenerated}
                      isNavPrevArrowDisabled={isBillingCycleNotGenerated}
                      isClientView={isClientView}
                    />
                    <Box>
                      {isClientView && showTsNav && (
                        <IssueInvoiceAction
                          invoiceData={invoiceData}
                          invoiceDataAction={invoiceDataAction}
                          issueInvoiceMode={issueInvoiceMode}
                        />
                      )}
                    </Box>
                  </Box>
                </>
              )}
              {((!isBillingCycleNotGenerated && !billingSummaryError && !isClientView) || showTsNav) && (
                <BillingSummaryFilters groupBy={groupBy} onGroupByChange={setGroupBy} />
              )}
              {isLoadingBillingSummaryData && <BillingSummaryWorkStatementSkeleton />}
              {!isLoadingBillingSummaryData && isMissingWorkStatements && !isClientView && <BillingSummaryEmptyState />}
              {workStatements.map((workStatement) => (
                <BillingSummaryWorkStatement
                  key={workStatement.id}
                  workStatement={workStatement}
                  billingCycle={billingCycle}
                  isClientView={isClientView}
                  onClick={handleSetSelectedWorkStatement}
                  onCheckboxChange={onCheckboxChange}
                  invoiceData={invoiceData}
                />
              ))}
            </Box>
          </Stack>
        </CardWrapper>
      </Box>

      <WorkStatementDetailsSideCard
        workStatement={selectedWorkStatement}
        billingCycle={billingCycle}
        handleClose={handleClose}
        isOpen={isWorkStatementDetailsSideCardOpen}
        openEditOrder={setEditWorkStatementModalOpen}
        showWorkStatementSnapshot={showWorkStatementSnapshot}
      />
      <AddWorkStatementModal
        projectId={id}
        open={isAddWorkStatementModalOpen}
        onClose={setAddWorkStatementModalClose}
        projectStartDate={startDate}
        projectEndDate={endDate}
        projectCurrency={projectDetails.currency}
        useProjectStartDate={isMissingWorkStatements}
      />
      {selectedWorkStatement && (
        <EditWorkStatementModal
          open={isEditWorkStatementModalOpen}
          onClose={setEditWorkStatementModalClose}
          projectStartDate={startDate}
          projectEndDate={endDate}
          projectCurrency={projectDetails.currency}
          workStatement={selectedWorkStatement}
          useProjectStartDate={workStatements.length === 1}
        />
      )}
    </Stack>
  );
};
