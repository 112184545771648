import { MouseEventHandler, useEffect, useRef, useState } from 'react';

import { SxProps } from '@mui/system';
import { Tooltip } from 'lux/components';

import { HourType } from 'api/types/HourType.enum';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { useUpdateBillingDay } from 'hooks/useUpdateBillingDay/useUpdateBillingDay';
import { useWorkTimeCell } from 'hooks/useWorkTimeCell/useWorkTimeCell';
import { useWorkTimeTooltipMessage } from 'hooks/useWorkTimeTooltipMessage/useWorkTimeTooltipMessage';
import { Loader } from 'ui/loader/Loader';
import { areDatesInTheSameDay, formatDate, parseDate } from 'utils/dateUtils';
import { TableCell } from '../../tableComponents/tableComponents';

import { selectedCellStore, SelectedWorkTimeCell } from './selectedWorkTimeCellStore';
import * as styles from './WorkTimeCell.styles';
import { WorkTimeCellProps } from './WorkTimeCell.types';
import { WorkTimePopover } from './workTimePopover/WorkTimePopover';
import { PopoverSaveHandler } from './workTimePopover/WorkTimePopover.types';

export const WorkTimeCell = ({
  assignmentWorkDay,
  employeeId,
  assignmentId,
  hoursTypeToDisplay = HourType.total,
  rateTypeToDisplay,
  rateToDisplay,
  dayNumber,
  isRootRow = false,
  rateTypeUnit,
}: WorkTimeCellProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const showLoader = useRef(false);

  const { billingCycleId, isFetchingTimesheetData, showTimesheetSnapshot, isReport } = useProjectTimesheetData();
  const {
    isOutsideProjectDates,
    isWeekend,
    isAbsent,
    isClickable,
    isDataMismatch,
    isWorkTimeAvailable,
    isProjectStartDayMarker,
    cellClassNames,
    mismatchWorkTimeText,
    showTooltip,
    getCellDisplayValue,
    getFilteredWorkTimeSum,
  } = useWorkTimeCell(assignmentWorkDay, dayNumber);
  const { mutate: updateBillingDay, isLoading: isUpdatingBillingDay } = useUpdateBillingDay();
  const tooltipMessage = useWorkTimeTooltipMessage(isOutsideProjectDates, isDataMismatch, mismatchWorkTimeText);

  const currentWorkTimeCell: SelectedWorkTimeCell = {
    ...assignmentWorkDay,
    employeeId,
    isRoot: isRootRow,
  };

  const [isSelected, setIsSelected] = useState(false);
  const assignmentDate = parseDate(assignmentWorkDay.date);
  useEffect(() => {
    const isCurrentCellSelected = (selectedCell: SelectedWorkTimeCell | null): boolean =>
      !!(
        (!!anchorEl || selectedCell?.isRoot) &&
        selectedCell &&
        selectedCell.employeeId === employeeId &&
        areDatesInTheSameDay(parseDate(selectedCell.date), assignmentDate)
      );
    const updateSelection = () => {
      setIsSelected(isCurrentCellSelected(selectedCellStore.get()));
    };

    const unsubscribe = selectedCellStore.subscribe(updateSelection);
    updateSelection();

    return () => {
      unsubscribe();
    };
  }, [employeeId, assignmentDate]);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isClickable) return;
    setAnchorEl(event.currentTarget);
    selectedCellStore.set(currentWorkTimeCell);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    selectedCellStore.set(null);
  };

  const handleSavePopover: PopoverSaveHandler = (items) => {
    showLoader.current = true;
    updateBillingDay({
      assignmentId,
      billingCycleId,
      billingDay: {
        date: formatDate(parseDate(currentWorkTimeCell.date)),
        items,
      },
    });
    handleClosePopover();
  };

  if (!isFetchingTimesheetData && !isUpdatingBillingDay && showLoader.current) {
    showLoader.current = false;
  }

  const workTimeSum = getFilteredWorkTimeSum(hoursTypeToDisplay, rateToDisplay, rateTypeToDisplay);
  const cellDisplayValue = getCellDisplayValue(workTimeSum, rateTypeUnit);
  const isLoading = showLoader.current && (isUpdatingBillingDay || isFetchingTimesheetData);

  const sx: SxProps = styles.cell({
    isProjectStartDayMarker,
    isCellEdited: assignmentWorkDay.edited,
  });

  return (
    <>
      <Tooltip placement="top" arrow title={showTooltip && tooltipMessage} PopperProps={{ sx: styles.popper }}>
        <TableCell
          isWeekend={isWeekend}
          isClickable={isClickable}
          isOutsideProjectDates={!isWorkTimeAvailable}
          isAbsent={isAbsent}
          isSelected={isSelected}
          isDataMismatch={isDataMismatch}
          isReport={isReport}
          showTimesheetSnapshot={showTimesheetSnapshot}
          isDetailsRow={!isRootRow}
          onClick={handleClick}
          sx={sx}
          className={cellClassNames}
        >
          {isLoading ? <Loader sx={styles.loader} /> : cellDisplayValue}
        </TableCell>
      </Tooltip>
      {anchorEl && (
        <WorkTimePopover
          workTimeEntries={currentWorkTimeCell.items}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          onSave={handleSavePopover}
          hoursTypeToDisplay={hoursTypeToDisplay}
        />
      )}
    </>
  );
};
