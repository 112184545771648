import { Box, Divider, Stack } from '@mui/material';
import { Avatar, Badge, CardHeader, DataDisplay, Grid, Typography } from 'lux/components';

import { fteToHumanValue } from 'app/need/utils';
import { AssignmentDetailsMenuButton } from 'app/project/teamDetails/tabAssigned/assignmentDetailsMenuButton/AssignmentDetailsMenuButton';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { AppMessages } from 'i18n/messages';
import { AssignmentStatusBadge } from 'ui/assignmentStatusBadge/AssignmentStatusBadge';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';
import { DashboardSideCard } from 'ui/dashboardSideCard/DashboardSideCard';
import { EmojiBadges } from 'ui/emojiBadges/EmojiBadges';
import { Loader } from 'ui/loader/Loader';
import { OverflowTooltipTypography } from 'ui/overflowTooltipTypography/OverflowTooltipTypography';
import { formatRoleName, formatSeniorityName } from 'utils/stringUtils';
import { AssignmentDetailsDataDisplay } from '../assignmentDetailsDataDisplay/AssignmentDetailsDataDisplay';
import { AssignmentMenuButton } from '../tabAssigned/assignmentMenuButton/AssignmentMenuButton';

import { AssignmentExtensionDetails } from './assignmentExtensionDetails/AssignmentExtensionDetails';
import * as styles from './AssignmentInfoCard.styles';
import { AssignmentWorkTimeInfo } from './assignmentWorkTimeInfo/AssignmentWorkTimeInfo';

export const AssignmentInfoCard = () => {
  const { assignment, setClose, projectAssignments } = useAssignmentInfo();
  const { projectDetails } = useProjectDetails();
  const { formatMessage } = useLocale();

  if (!assignment) {
    return (
      <DashboardSideCard onClose={setClose}>
        <Loader fullHeight={false} />
      </DashboardSideCard>
    );
  }

  return (
    <CardWrapper sx={{ padding: 0, height: '100%' }}>
      <DashboardSideCard
        onClose={setClose}
        additionalHeaderButton={
          <AssignmentMenuButton assignmentData={assignment} projectAssignments={projectAssignments} />
        }
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 1.5 }}>
          <Stack gap={1.5} direction="row">
            <Avatar
              image={assignment.person.picture}
              size="lg"
              alt={`${assignment.person.firstName} ${assignment.person.lastName}`}
            />
            <Stack justifyContent="center">
              <Stack direction="row" gap={1} alignItems="center">
                <OverflowTooltipTypography
                  text={`${assignment.person.firstName} ${assignment.person.lastName}`}
                  variant="body1"
                />
                <EmojiBadges
                  isLeader={assignment.isLeader}
                  employmentType={assignment.person.employmentType}
                  proposalCount={assignment.person.proposalCount}
                  employedTo={assignment.person.employedTo}
                  absences={assignment.person.absences}
                  assignment={assignment}
                />
              </Stack>
              <Typography color="text.secondary" variant="body2">
                {formatRoleName(assignment.role, assignment.seniority)}
              </Typography>
            </Stack>
          </Stack>
          <AssignmentStatusBadge status={assignment.status} />
        </Stack>
        <Box marginBottom={1.5}>
          {assignment.person.skills && (
            <Stack gap={0.5} direction="row" flexWrap="wrap" marginBottom={1.5}>
              {assignment.person.skills.map(({ id, name }) => (
                <Badge isStatic key={id} color="gray" badgeContent={name} />
              ))}
            </Stack>
          )}
        </Box>
        {assignment.extensionDate && (
          <AssignmentExtensionDetails date={assignment.extensionDate} assignmentId={assignment.id} />
        )}
        <AssignmentWorkTimeInfo startDate={assignment.startDate} endDate={assignment.endDate} />
        <Box sx={styles.detailsTab}>
          <Grid container item display="flex" flexDirection="column" gap={2}>
            <Grid item>
              <CardHeader
                title={formatMessage({ id: AppMessages['card.details.title'] })}
                sx={{ heading: styles.detailsHeading, wrapper: styles.detailsHeadingWrapper }}
              >
                <AssignmentDetailsMenuButton assignmentData={assignment} />
              </CardHeader>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item display="flex" flexDirection="column" gap={2} paddingX={2}>
              <AssignmentDetailsDataDisplay header={formatMessage({ id: AppMessages['card.details.seniority'] })}>
                {formatSeniorityName(assignment.seniority) || '-'}
              </AssignmentDetailsDataDisplay>
              <AssignmentDetailsDataDisplay header={formatMessage({ id: AppMessages['card.details.role'] })}>
                {assignment.role}
              </AssignmentDetailsDataDisplay>
              {projectDetails.plannedExtension && (
                <AssignmentDetailsDataDisplay header={formatMessage({ id: AppMessages['card.details.extensionDate'] })}>
                  {assignment.extensionDate || '-'}
                </AssignmentDetailsDataDisplay>
              )}
              <AssignmentDetailsDataDisplay header={formatMessage({ id: AppMessages['card.details.fte'] })}>
                {formatMessage({ id: AppMessages[fteToHumanValue(assignment.fte)] }, { fte: assignment.fte })}
              </AssignmentDetailsDataDisplay>
              <AssignmentDetailsDataDisplay header={formatMessage({ id: AppMessages['card.details.rate'] })}>
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Typography variant="caption">{assignment.currentRate ?? '-'}</Typography>
                    <Typography fontWeight={600} color="text.secondary" variant="caption">
                      &nbsp;{projectDetails.currentRateCard.currency}
                    </Typography>
                  </Box>
                  {assignment.isCustomRate && (
                    <Box>
                      <Badge
                        isStatic
                        color="gray"
                        badgeContent={formatMessage({ id: AppMessages['card.details.customRate'] })}
                      />
                    </Box>
                  )}
                </Stack>
              </AssignmentDetailsDataDisplay>
              <AssignmentDetailsDataDisplay header={formatMessage({ id: AppMessages['card.details.temporary'] })}>
                {formatMessage({
                  id: assignment.isTemporary
                    ? AppMessages['card.details.temporary.yes']
                    : AppMessages['card.details.temporary.no'],
                })}
              </AssignmentDetailsDataDisplay>

              <DataDisplay
                header={
                  <Typography variant="caption">{formatMessage({ id: AppMessages['card.details.notes'] })}</Typography>
                }
              >
                {assignment.endDate || '-'}
              </DataDisplay>
            </Grid>
          </Grid>
        </Box>
      </DashboardSideCard>
    </CardWrapper>
  );
};
