import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { enqueueSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { Seniority } from 'api/types/Seniority.enum';
import { NeedType } from 'api/types/NeedType.enum';
import { absenceReplacementSchema } from '../AbsenceReplacementModal.schema';
import { useRequestNeed } from 'hooks/useRequestNeed/useRequestNeed';
import { formatDate, parseDate } from 'utils/dateUtils';
import { sortSkillsByWeight } from 'utils/sortSkillsByWeight';
import { AppMessages } from 'i18n/messages';
import { useProject } from 'hooks/useProject/useProject';
import { Loader } from 'ui/loader/Loader';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { NeedForm } from 'app/need/form/NeedForm';
import { getApiError } from 'api/utils/getApiError';

import {
  AbsenceReplacementContentProps,
  AbsenceReplacementFormValues,
  AbsenceReplacementPayload,
} from './AbsenceReplacementContent.types';

export const AbsenceReplacementContent = ({ projectId, onClose, assignmentData }: AbsenceReplacementContentProps) => {
  const { formatMessage } = useLocale();
  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();
  const { mutateAsync, isLoading } = useRequestNeed(projectId);
  const { data: projectData, isLoading: isProjectLoading } = useProject(projectId);

  const nearestAbsence =
    assignmentData.person.absences.timePeriod.length > 0 ? assignmentData.person.absences.timePeriod[0] : null;

  const defaultValues: AbsenceReplacementFormValues = {
    role: assignmentData.role,
    seniorityEnabled: assignmentData.seniority !== Seniority.withoutLevel,
    seniority: assignmentData.seniority,
    fte: assignmentData.fte.toString(),
    startDate: nearestAbsence ? parseDate(nearestAbsence.from) : null,
    endDate: nearestAbsence ? parseDate(nearestAbsence.to) : null,
    type: NeedType.replacement,
    replacedAssignmentId: assignmentData.id,
    additionalInformation: formatMessage(
      { id: AppMessages['assignment.absenceReplacement.additionalInfo'] },
      {
        replacedAssignment: `${assignmentData.person.firstName} ${assignmentData.person.lastName}`,
      },
    ),
  };

  const form = useIntlForm<AbsenceReplacementFormValues>({
    defaultValues,
    mode: 'onSubmit',
    resolver: joiResolver(absenceReplacementSchema(projectData?.startDate)),
  });

  const onSubmit = async (need: AbsenceReplacementFormValues) => {
    const skillSet = need.skillSet ? need.skillSet.map(({ name, weight, note }) => ({ name, weight, note })) : [];

    const payload: AbsenceReplacementPayload = {
      role: need.role,
      fte: need.fteCustom || need.fte,
      startDate: need.startDate ? formatDate(need.startDate) : null,
      type: NeedType.replacement,
      replacedAssignmentId: assignmentData.id,
      seniority: need.seniority || Seniority.withoutLevel,
      additionalInformation: need.additionalInformation,
      endDate: need.endDate ? formatDate(need.endDate) : null,
      skillSet: skillSet.length > 0 ? sortSkillsByWeight(skillSet) : undefined,
    };

    await mutateAsync(payload, {
      onSuccess: () => {
        enqueueSnackbar(formatMessage({ id: AppMessages['assignment.absenceReplacement.snackbar.success'] }));
        onClose();
      },
      onError: (error) => {
        getApiError(error);
      },
    });
  };

  if (isProjectLoading) {
    return <Loader fullHeight={false} />;
  }

  return (
    <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={onClose}>
      <DialogInnerWrapper
        title={formatMessage({ id: AppMessages['assignment.absenceReplacement.title'] })}
        loading={isLoading}
        onClose={onClose}
        onSubmit={form.handleSubmit(onSubmit)}
        isFormValid={form.formState.isValid}
        submitText={formatMessage({ id: AppMessages['assignment.absenceReplacement.submitText'] })}
      >
        <FormProvider {...form}>
          <NeedForm loading={isLoading} projectId={projectId} variant={'replacement'} />
        </FormProvider>
      </DialogInnerWrapper>
    </DialogClickAwayListenerWrapper>
  );
};
