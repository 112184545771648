import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { PeopleListTooltipTitleProps } from './PeopleListTooltipTitle.types';

const ColorsEnum = {
  RED: 'red',
  GREEN: 'green',
};

export const PeopleListTooltipTitle = ({ assignment, proposals }: PeopleListTooltipTitleProps) => {
  const { formatMessage } = useLocale();

  return (
    <span>
      {assignment && (
        <>
          <Typography color={ColorsEnum.RED}>
            {formatMessage({ id: AppMessages['dashboard.availability.general.tooltipTitle.left'] })}
          </Typography>
          {assignment.project.name} - {assignment.project.client.name}
          <br />
        </>
      )}
      {proposals.length > 0 && (
        <>
          <Typography color={ColorsEnum.GREEN}>
            {formatMessage({ id: AppMessages['dashboard.availability.general.tooltipTitle.proposed'] })}
          </Typography>
          {proposals.map((proposal) => `${proposal.project.name} - ${proposal.project.client.name}`).join('; ')}
        </>
      )}
    </span>
  );
};
