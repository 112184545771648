import { Styles } from 'styles/theme';

export const dialogContent: Styles = {
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',

  '& .MuiTableCell-root.RatesHistoryModal-seniorityCell, & .MuiTableCell-root.RatesHistoryModal-rateCell': {
    paddingY: 1,
  },
};

export const cell = (coloredBackground?: boolean): Styles => ({
  backgroundColor: coloredBackground ? 'surface.paper.default' : 'surface.default',
});

export const headerCell: Styles = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: 'surface.default',
  border: 0,
  padding: 0,
};

export const headerLeftCell: Styles = {
  ...headerCell,
  left: 0,
  zIndex: 2,
};

export const headerCellTypography = ({ coloredBackground }: { coloredBackground?: boolean } = {}): Styles => ({
  ...cell(coloredBackground),
  display: 'block',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  paddingX: 2,
  paddingY: 1.75,
});

export const roleColumnCell: Styles = {
  position: 'sticky',
  left: 0,
  backgroundColor: 'surface.default',
};

export const roleRowCell = ({ coloredBackground }: { coloredBackground?: boolean } = {}): Styles => ({
  ...cell(coloredBackground),
  paddingX: 0,
  paddingBottom: 0,
  border: 0,
  '&.MuiTableCell-root': {
    paddingTop: 2,
    height: 'auto',
  },
});

export const roleCell: Styles = {
  ...roleColumnCell,
  ...roleRowCell(),
};

export const rateTypeRowCell = ({ coloredBackground }: { coloredBackground?: boolean } = {}): Styles => ({
  ...cell(coloredBackground),
  '&.MuiTableCell-root': {
    paddingTop: 5,
    height: 'auto',
  },
});

export const rateRowCell = ({ coloredBackground }: { coloredBackground?: boolean } = {}): Styles => ({
  ...cell(coloredBackground),
});

export const seniorityCell: Styles = {
  ...rateRowCell,
  ...roleColumnCell,
};
