import { SxProps } from '@mui/system';

import { Styles } from 'styles/theme';

export const table: Styles = {
  '&& .MuiTableHead-root .MuiTableCell-root': {
    pl: 0,
  },
  '&& .MuiTableBody-root .MuiTableCell-root': {
    pl: 1,
  },
};

export const firstColumn = (firstColumnSize: number): SxProps => ({
  '&.MuiTableCell-head.MuiTableCell-root': {
    width: `${firstColumnSize}ch`,
  },
});

export const workTimeCell: SxProps = {
  '&.MuiTableCell-head.MuiTableCell-root': {
    width: '120px', // INFO: we can't set less beacause head is "label" so inner content is TableSortLabel with fixed width
  },
};
