import { Alert, Button } from 'lux/components';
import { NavLink } from 'react-router-dom';

import { Translation as TranslationType } from 'i18n/messages';
import { Translation } from 'ui/translation/Translation';

import * as styles from './DiscrepancyWorkTimeWarning.styles';
import { DiscrepancyWorkTimeWarningProps } from './DiscrepancyWorkTimeWarning.types';

export const DiscrepancyWorkTimeWarning = ({
  message,
  actionLabel,
  to,
  modifiedText = false,
}: DiscrepancyWorkTimeWarningProps) => (
  <Alert
    severity="warning"
    description={modifiedText ? message : <Translation id={message as TranslationType} />}
    sx={styles.warningText}
    action={
      actionLabel ? (
        <Button variant="text" sx={styles.actionButton} to={to} component={NavLink} end>
          <Translation id={actionLabel} />
        </Button>
      ) : undefined
    }
  />
);
