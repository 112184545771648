import { createContext, useCallback, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { steps } from 'config/data/create-project/steps';
import { useCreateClient } from 'hooks/useCreateClient/useCreateClient';
import { useCreateProject } from 'hooks/useCreateProject/useCreateProject';
import { AppRoute } from 'routing/AppRoute.enum';
import { checkIsInternal } from 'utils/check-internal-project';
import { clientSchema, projectSchema, rateSchema } from '../CreateProject.schema';

import {
  ActiveStep,
  FormNavigationContextControllerProps,
  FormNavigationContextValueType,
} from './FormNavigationContext.types';

export const FormNavigationContext = createContext<FormNavigationContextValueType | undefined>(undefined);

export const FormNavigationContextController = ({ children, onSubmit }: FormNavigationContextControllerProps) => {
  const form = useFormContext();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<ActiveStep>(0);
  const location = useLocation();
  const from = location.state?.from || AppRoute.dashboard;

  const { isLoading: isClientLoading } = useCreateClient();
  const { isLoading: isProjectLoading } = useCreateProject();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const setLoading = (state: boolean) => {
    setIsLoading(state);
  };
  const setDisabled = (state: boolean) => {
    setIsDisabled(state);
  };

  const doesCurrentStepHasVisibleErrors = () => {
    const stepErrorKey = ActiveStep[activeStep];

    const stepError = form.formState.errors?.[stepErrorKey];

    return !!stepError && Object.keys(stepError).length > 0;
  };

  const currentStepHasVisibleErrors = doesCurrentStepHasVisibleErrors();

  const triggerErrorOrProceed = () => {
    if (isStepInvalid(activeStep)) {
      form.trigger();
    } else {
      form.clearErrors();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNext = async () => {
    if (activeStep < steps().length - 1) {
      triggerErrorOrProceed();
    } else {
      await onSubmit();
    }
  };

  const handleBack = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      navigate(from);
    }
  }, [activeStep, navigate]);

  const [client, clientId, project, rate] = form.watch(['client', 'clientId', 'project', 'rate']);

  const isStepInvalid = useCallback(
    (step: number): boolean => {
      const isInternal = checkIsInternal(client.name);
      const projectNameNotUnique = form.formState.errors.project?.name;
      const projectCodenameNotUnique = form.formState.errors.project?.codename;
      const { error: clientError } = clientSchema().validate({ client, clientId });
      const { error: projectError } = projectSchema().validate({ project });
      const { error: rateCardError } = rateSchema().validate({ rate });

      switch (step) {
        case 0:
          return !!clientError;
        case 1:
          return !!projectError || !!projectNameNotUnique || !!projectCodenameNotUnique;
        case 2:
          return !isInternal && !!rateCardError;
      }
      return false;
    },
    [client, clientId, project, rate, form.formState.errors.project?.name],
  );

  const shouldDisableStepperStep = (index: number) => {
    const invalidityConditions = [false, isStepInvalid(0), isStepInvalid(0) || isStepInvalid(1)];

    if (index >= 0 && index < invalidityConditions.length) {
      return invalidityConditions[index];
    }

    return false;
  };

  const handleStepperNavigation = (index: number) => {
    if (!shouldDisableStepperStep(index)) {
      setActiveStep(index);
    }
  };

  const value: FormNavigationContextValueType = useMemo(
    () => ({
      activeStep,
      handleNext,
      handleBack,
      isStepInvalid,
      shouldDisableStepperStep,
      handleStepperNavigation,
      isLoading: isClientLoading || isProjectLoading || isLoading,
      setLoading,
      isDisabled: isStepInvalid(activeStep) || isDisabled || isLoading,
      setDisabled,
      currentStepHasVisibleErrors,
    }),
    [
      activeStep,
      handleNext,
      handleBack,
      isStepInvalid,
      shouldDisableStepperStep,
      handleStepperNavigation,
      isClientLoading,
      isProjectLoading,
      isLoading,
      setLoading,
      isStepInvalid(activeStep),
      isDisabled,
      setDisabled,
      currentStepHasVisibleErrors,
    ],
  );

  return <FormNavigationContext.Provider value={value}>{children}</FormNavigationContext.Provider>;
};
