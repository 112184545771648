import { useState } from 'react';

import { Stack } from '@mui/material';
import { Table } from 'lux/components';

import { useBillingSummaryMemberWorkTime } from 'hooks/useBillingSummaryMemberWorkTime/useBillingSummaryMemberWorkTime';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { CollapseRow } from '../../collapseRow/CollapseRow';
import { WorkTimeDetailsRow } from '../../WorkTimeDetailsRow/WorkTimeDetailsRow';

import * as styles from './WorkStatementRole.styles';
import { WorkStatementRoleProps } from './WorkStatementRole.types';

export const WorkStatementRole = ({ role }: WorkStatementRoleProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { formatPrice } = useBillingSummaryPrice();
  const { name, items: workTimeEntries, total } = role;

  const { rateText, totalWorkTime } = useBillingSummaryMemberWorkTime({ ...role, useDashForMultipleWorkTime: true });

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Stack direction="row" alignItems="center" gap={1}>
            {name}{' '}
            <CollapseButton
              sx={styles.collapseButton}
              isExpanded={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </Stack>
        </Table.Cell>
        <Table.Cell>{totalWorkTime}</Table.Cell>
        <Table.Cell>{rateText}</Table.Cell>
        <Table.Cell>{formatPrice(total)}</Table.Cell>
      </Table.Row>
      <CollapseRow isExpanded={isExpanded}>
        {workTimeEntries &&
          workTimeEntries.map((workTime, idx) => (
            <WorkTimeDetailsRow workTime={workTime} key={idx} hasPadding={false} />
          ))}
      </CollapseRow>
    </>
  );
};
