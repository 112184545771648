import { Fragment } from 'react';

import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button, Table, Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Translation } from 'ui/translation/Translation';
import { parseDate } from 'utils/dateUtils';
import { formatSeniorityName } from 'utils/stringUtils';

import * as styles from './RatesHistoryModal.styles';
import { RatesHistoryModalProps } from './RatesHistoryModal.types';
import { getRatesHistoryData } from './getRatesHistoryData';

export const RatesHistoryModal = ({ open, onClose, rateCards }: RatesHistoryModalProps) => {
  const { formatMessage } = useLocale();

  const sortedRateCards = [...rateCards].sort(
    (a, b) => parseDate(a.appliesFrom).getTime() - parseDate(b.appliesFrom).getTime(),
  );
  const dates = sortedRateCards.reduce<string[]>((acc, current) => [...acc, current.appliesFrom], []);

  const ratesHistoryData = getRatesHistoryData(sortedRateCards);

  const getMaxWidth = () => {
    if (dates.length < 3) {
      return 'sm';
    }
    if (dates.length < 6) {
      return 'md';
    }
    return 'lg';
  };

  return (
    <Dialog maxWidth={getMaxWidth()} fullWidth open={open} onClose={onClose}>
      <DialogTitle>{formatMessage({ id: AppMessages['rateCard.ratesHistoryModal.title'] })}</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Box
          display="grid"
          flexShrink={1}
          gridTemplateColumns={`200px repeat(${dates.length}, minmax(104px, 1fr))`}
          overflow="auto"
        >
          <Table.Cell component={Box} sx={styles.headerLeftCell} />
          {dates.map((date, index) => (
            <Table.Cell padding="none" component={Box} sx={styles.headerCell} key={date}>
              <Typography variant="caption" sx={styles.headerCellTypography({ coloredBackground: !(index % 2) })}>
                {date}
              </Typography>
            </Table.Cell>
          ))}
          <Table.Cell padding="none" component={Box} sx={styles.rateTypeRowCell()}>
            <Typography variant="subtitle2">
              <Translation id="projectDetails.accordions.details.rateCard.rateType" />
            </Typography>
          </Table.Cell>
          {sortedRateCards.map(({ type }, index) => (
            <Table.Cell key={type} component={Box} sx={styles.rateTypeRowCell({ coloredBackground: !(index % 2) })}>
              <Typography variant="caption">{type}</Typography>
            </Table.Cell>
          ))}
          {Object.entries(ratesHistoryData).map(([role, seniorityRates]) => (
            <Fragment key={role}>
              <Table.Cell padding="none" component={Box} sx={styles.roleCell}>
                <Typography variant="subtitle2">{role}</Typography>
              </Table.Cell>
              {dates.map((date, index) => (
                <Table.Cell
                  padding="none"
                  component={Box}
                  key={`${role}${date}`}
                  sx={{
                    ...styles.roleRowCell({
                      coloredBackground: !(index % 2),
                    }),
                  }}
                />
              ))}

              {Object.entries(seniorityRates).map(([seniority, rates]) => (
                <Fragment key={`${role}${seniority}`}>
                  <Table.Cell
                    className="RatesHistoryModal-seniorityCell"
                    component={Box}
                    size="small"
                    sx={styles.seniorityCell}
                  >
                    <Typography variant="caption">{formatSeniorityName(seniority, false)}</Typography>
                  </Table.Cell>
                  {rates.map((rate, index) => (
                    <Table.Cell
                      component={Box}
                      size="small"
                      className="RatesHistoryModal-rateCell"
                      sx={styles.rateRowCell({ coloredBackground: !(index % 2) })}
                      key={`${seniority}${index}`}
                    >
                      <Typography variant="caption">
                        {rate.amount || '-'} {rate.amount && rate.currency}
                      </Typography>
                    </Table.Cell>
                  ))}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlinedGray" onClick={onClose} fullWidth>
          {formatMessage({ id: AppMessages['rateCard.ratesHistoryModal.button.back'] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
