import type { ReactNode } from 'react';

export enum ActiveStep {
  client,
  project,
  rate,
}

export type FormNavigationContextValueType = {
  activeStep: ActiveStep;
  handleBack: () => void;
  handleNext: () => Promise<void>;
  isStepInvalid: (step: number) => boolean;
  isLoading: boolean;
  setLoading: (state: boolean) => void;
  isDisabled: boolean;
  setDisabled: (state: boolean) => void;
  shouldDisableStepperStep: (index: number) => boolean;
  handleStepperNavigation: (index: number) => void;
  currentStepHasVisibleErrors: boolean;
};

export type FormNavigationContextControllerProps = {
  children: ReactNode;
  onSubmit: () => Promise<void>;
};
