import { EmployeeId } from 'api/actions/getPerson/getPerson.types';

import { AssignmentId } from './AssignmentType.enum';
import { InvoiceStatusEnum } from './BillingSummary.types';
import { HourType } from './HourType.enum';
import { RateType } from './Rate.types';

export enum SelectedBillingCycleKey {
  SelectedBillingCycle = 'selectedBillingCycle',
}

export type TimesheetWorkTime = {
  amount: number;
  type: HourType;
  rateType: RateType;
  ratePercentage: number;
  note?: string;
};

export type Assignment = {
  employeeId: EmployeeId;
  role: string;
  firstName: string;
  lastName: string;
  isMismatch: boolean;
  picture: string | null;
  displayTotalWorkTime: number;
  displayRateType: RateType;
  billingDays: [
    {
      assignmentId: AssignmentId;
      date: string;
      items: TimesheetWorkTime[];
      displayDailyWorkTime: number;
      displayDailyRateType: RateType;
      edited: boolean;
      dataMismatch: DataMismatch;
    },
  ];
};

export type DataMismatch = {
  days?: MismatchValue;
  hours?: MismatchValue;
};

export type MismatchValue = {
  current: number;
  snapshot: number;
};

export type WorkStatement = {
  id: string;
  statementNumber: string;
  startDate: string;
  endDate: string;
  displayTotalWorkTime: number;
  displayRateType: RateType;
  assignments: Assignment[];
  invoiceStatus?: InvoiceStatusEnum;
};

export type Timesheet = {
  billingCycleId: string;
  updatedAt: string;
  workStatements: WorkStatement[];
  username: string;
};
