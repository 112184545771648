import { Stack } from '@mui/system';

import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import { MultipleAutocompleteEdit } from 'ui/form/inlineEdit/autocompleteEdit/MultipleAutocompleteEdit';
import { TextFieldEdit } from 'ui/form/inlineEdit/textFieldEdit/TextFieldEdit';
import { SelectEdit } from 'ui/form/inlineEdit/selectEdit/SelectEdit';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { countriesList } from 'config/data/countries';

import { ClientDetailsRowsProps } from './ClientDetailsRows.types';

export const ClientDetailsRows = (props: ClientDetailsRowsProps) => {
  const { clientData, disabled, mutateAsync, isLoading, otherClientNames, industries, updateClientName, isClientPage } =
    props;

  const handleSubmit = (name: string) => {
    if (!updateClientName) {
      return Promise.resolve();
    }
    return updateClientName(name);
  };

  return (
    <Stack gap={1}>
      {isClientPage ? (
        <TextFieldEdit
          name="name"
          value={clientData.name}
          label="projectDetails.accordions.details.client.name"
          defaultValues={{ name: clientData.name }}
          onSubmit={mutateAsync}
          loading={isLoading}
        />
      ) : (
        <SelectEdit
          name="name"
          value={clientData.name}
          label="projectDetails.accordions.details.client.name"
          defaultValues={{ name: clientData.name }}
          onSubmit={(client) => handleSubmit(client.name)}
          options={mapToSelectOptions(otherClientNames)}
          loading={isLoading}
          disabled={disabled}
        />
      )}
      <MultipleAutocompleteEdit
        name="industries"
        value={clientData?.industries}
        label="projectDetails.accordions.details.client.industries"
        defaultValues={{ industries: clientData?.industries }}
        onSubmit={mutateAsync}
        options={mapToAutocompleteOptions(industries)}
        isFreeSolo
        loading={isLoading}
        disabled={disabled}
      />
      <SelectEdit
        name="country"
        value={clientData?.country}
        label="projectDetails.accordions.details.client.country"
        defaultValues={{ country: clientData?.country }}
        onSubmit={mutateAsync}
        options={mapToSelectOptions(countriesList)}
        loading={isLoading}
        disabled={disabled}
      />
      <TextFieldEdit
        name="accountNumber"
        value={clientData?.accountNumber || ''}
        label="projectDetails.accordions.details.client.accountNumber"
        defaultValues={{ accountNumber: clientData?.accountNumber }}
        onSubmit={mutateAsync}
        loading={isLoading}
        disabled={disabled}
      />
    </Stack>
  );
};
