import { countries } from 'config/data/countries';

import type { FlagLabelProps } from './FlagLabel.types';

export const getUnicodeFlag = (code = '') => {
  const isCodeValid = countries.find((country) => country.code === code);

  if (!isCodeValid) {
    return '🌐';
  }

  return [...code.toUpperCase()].map((char) => String.fromCodePoint(0x1f1e6 + char.charCodeAt(0) - 65)).join('');
};

export const FlagLabel = ({ countryName }: FlagLabelProps) => {
  const countryCode = countries.find((country) => country.name === countryName)?.code;

  return (
    <>
      {getUnicodeFlag(countryCode)} {countryName}
    </>
  );
};
