import Joi from 'joi';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';
import { RateCardFormItem } from '../rateCardItem/RateCardItem.types';

type RateKey = 'juniorRate' | 'regularRate' | 'seniorRate';

const allowZeroValidation = ['pm', 'project manager', 'em', 'engineering manager'];

const minRateValidation = Joi.ref('role', {
  adjust: (value) => Number(!allowZeroValidation.includes(value.toLowerCase())),
});

const emptyRateValidation = (key1: RateKey, key2: RateKey) => ({
  is: Joi.object({ [key1]: Joi.number().equal(''), [key2]: Joi.number().equal('') }).unknown(),
  otherwise: Joi.number().allow(''),
});

export const rateCardSchema = (isInternal = false) => {
  const { formatMessage } = intl.currentIntl;

  const rateValidation = isInternal ? Joi.number().allow(0) : Joi.number().min(minRateValidation);

  return Joi.object({
    role: Joi.string()
      .required()
      .custom((value, helpers) => {
        if (helpers.state.path) {
          const currentIndex = helpers.state.path[2];
          const otherRateCards = helpers.state.ancestors[1].filter(
            (rateCard: RateCardFormItem, index: number) => index !== Number(currentIndex),
          );
          if (otherRateCards.some(({ role }: RateCardFormItem) => role === value)) {
            return helpers.error('notUnique');
          }
        }

        return value;
      })
      .messages({
        'string.empty': formatMessage(
          {
            id: AppMessages['validation.empty'],
          },
          { label: formatMessage({ id: AppMessages['rateCard.form.role.label'] }) },
        ),
        notUnique: formatMessage({
          id: AppMessages['rateCard.form.role.validation.role.notUnique'],
        }),
      }),
    seniorityEnabled: Joi.boolean().required(),
  }).when(Joi.object({ seniorityEnabled: true }).unknown(), {
    then: Joi.object({
      withoutLevelRate: rateValidation.optional().allow(''),
      juniorRate: rateValidation
        .precision(2)
        .label(formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }))
        .when('..', emptyRateValidation('regularRate', 'seniorRate'))
        .required()
        .messages({
          'number.min': formatMessage(
            { id: AppMessages['validation.positive'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
            },
          ),
          'number.unsafe': formatMessage(
            { id: AppMessages['validation.unsafe'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
              maxValue: Number.MAX_SAFE_INTEGER,
            },
          ),
        }),
      regularRate: rateValidation
        .label(formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }))
        .precision(2)
        .when('juniorRate', {
          is: Joi.number(),
          then: Joi.number()
            .min(Joi.ref('juniorRate'))
            .when('role', {
              is: Joi.alternatives().try(...allowZeroValidation),
              then: rateValidation.min(Joi.ref('juniorRate')),
            })
            .messages({
              'number.min': formatMessage(
                { id: AppMessages['validation.min'] },
                {
                  label: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
                  limit: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
                },
              ),
            }),
        })
        .when('..', emptyRateValidation('juniorRate', 'seniorRate'))
        .optional()
        .messages({
          'number.min': formatMessage(
            { id: AppMessages['validation.min'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
              limit: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
            },
          ),
          'number.unsafe': formatMessage(
            { id: AppMessages['validation.unsafe'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
              maxValue: Number.MAX_SAFE_INTEGER,
            },
          ),
        }),
      seniorRate: rateValidation
        .label(formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }))
        .precision(2)
        .when('juniorRate', {
          is: Joi.number(),
          then: rateValidation.min(Joi.ref('juniorRate')).messages({
            'number.min': formatMessage(
              { id: AppMessages['validation.min'] },
              {
                label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
                limit: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
              },
            ),
          }),
        })
        .when('regularRate', {
          is: Joi.number(),
          then: rateValidation.min(Joi.ref('regularRate')).messages({
            'number.min': formatMessage(
              { id: AppMessages['validation.min'] },
              {
                label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
                limit: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
              },
            ),
          }),
        })
        .when('..', emptyRateValidation('juniorRate', 'regularRate'))
        .optional()
        .messages({
          'number.min': formatMessage(
            { id: AppMessages['validation.min'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
              limit: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
            },
          ),
          'number.unsafe': formatMessage(
            { id: AppMessages['validation.unsafe'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
              maxValue: Number.MAX_SAFE_INTEGER,
            },
          ),
        }),
    }),
    otherwise: Joi.object({
      withoutLevelRate: rateValidation
        .required()
        .precision(2)
        .messages({
          'number.positive': formatMessage(
            { id: AppMessages['validation.positive'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.withoutLevelRate.label'] }),
            },
          ),
          'number.base': formatMessage(
            { id: AppMessages['validation.required'] },
            { label: formatMessage({ id: AppMessages['rateCard.form.withoutLevelRate.label'] }) },
          ),
          'number.unsafe': formatMessage(
            { id: AppMessages['validation.unsafe'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.withoutLevelRate.label'] }),
              maxValue: Number.MAX_SAFE_INTEGER,
            },
          ),
        }),
      juniorRate: rateValidation.allow('').optional(),
      regularRate: rateValidation
        .allow('')
        .optional()
        .messages({
          'number.min': formatMessage(
            { id: AppMessages['validation.positive'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
            },
          ),
        }),
      seniorRate: rateValidation
        .allow('')
        .optional()
        .messages({
          'number.min': formatMessage(
            { id: AppMessages['validation.positive'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
            },
          ),
        }),
    }),
  });
};
