import { Box } from '@mui/material';
import { Grid } from 'lux/components';

import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { usePersonAssignments } from 'hooks/usePersonAssignments/usePersonAssignments';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { usePersonProposals } from 'hooks/usePersonProposals/usePersonProposals';
import { PersonAbsences } from '../personAbsences/PersonAbsences';
import { PersonDetails } from '../personDetails/PersonDetails';
import { PersonHistory } from '../personHistory/PersonHistory';
import { PersonProjects } from '../personProjects/PersonProjects';
import { PersonTimeline } from '../personTimeline/PersonTimeline';

export const PersonGrid = () => {
  const { person } = usePersonData();

  const { data: assignmentsData, isLoading: isLoadingAssignments } = usePersonAssignments(String(person.employeeId));

  const { data: proposalsData, isLoading: isLoadingProposals } = usePersonProposals(String(person.employeeId));

  const assignments = assignmentsData?.assignments || [];
  const currentAssignments = assignments.filter((assignment) => assignment.status !== AssignmentStatus.left);

  const assignmentRates = assignments.flatMap((assignment) =>
    person.currentPersonRate && assignment.status !== AssignmentStatus.left
      ? {
          currency: person.currentPersonRate.currency,
          rate: assignment.currentRateTable[person.currentPersonRate.currency],
        }
      : [],
  );

  return (
    <Box paddingY={3}>
      <Grid container gutter={0} alignItems="flex-start">
        <Grid item xs={8} container spacing={3}>
          <Grid item xs={12}>
            <PersonProjects
              assignments={currentAssignments}
              isLoadingAssignments={isLoadingAssignments}
              proposals={proposalsData?.proposals || []}
              isLoadingProposals={isLoadingProposals}
            />
          </Grid>
          <Grid item xs={12}>
            <PersonTimeline assignments={assignmentsData} proposals={proposalsData} />
          </Grid>
          <Grid item xs={12}>
            <PersonHistory />
          </Grid>
        </Grid>
        <Grid item xs={4} container spacing={3}>
          <Grid item xs={12}>
            <PersonDetails assignmentRates={assignmentRates} />
          </Grid>
          <Grid item xs={12}>
            <PersonAbsences />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
