import type { Styles } from 'styles/theme';

export const HEADER_HEIGHT = 56;

export const header: Styles = {
  backgroundColor: '#0E6BF9',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 2,
  paddingBottom: 2,
  position: 'relative',
  zIndex: 1300,
  height: HEADER_HEIGHT,
};
