import { Typography } from 'lux/components';

import { roundNumber } from 'utils/roundNumber';
import { getDisplayRateTypeUnit } from 'utils/timesheetData.utils';
import { formatWorkTime } from 'utils/workTime/workTime';
import { TableCell, TableRow } from '../../../tableComponents/tableComponents';
import { WorkTimeCell } from '../../workTimeCell/WorkTimeCell';
import { WorkTimeSum } from '../../workTimeSum/WorkTimeSum';

import * as styles from './TeamMemberWorkTimeDetailsRow.styles';
import { AssignmentWorkTimeDetailsRowProps } from './TeamMemberWorkTimeDetailsRow.types';

export const AssignmentWorkTimeDetailsRow = ({
  assignmentWorkDays,
  employeeId,
  hourType,
  rateType,
  title,
  rateToDisplay,
}: AssignmentWorkTimeDetailsRowProps) => {
  const workTimeSum = roundNumber(
    assignmentWorkDays.reduce((sum, memberWorkDay) => {
      const matchingWorkTime = memberWorkDay.items.find(
        (workTime) =>
          workTime.type === hourType && workTime.ratePercentage === rateToDisplay && workTime.rateType === rateType,
      );

      return matchingWorkTime ? sum + matchingWorkTime.amount : sum;
    }, 0),
    2,
  );

  return (
    <TableRow>
      <TableCell sx={styles.dimmedCell}>
        <Typography>{title}</Typography>
        <WorkTimeSum isDimmed>{formatWorkTime(workTimeSum, rateType)}</WorkTimeSum>
      </TableCell>
      {assignmentWorkDays.map((assignmentWorkDay, idx) => (
        <WorkTimeCell
          dayNumber={idx + 1}
          key={idx}
          assignmentWorkDay={assignmentWorkDay}
          employeeId={employeeId}
          assignmentId={assignmentWorkDay.assignmentId}
          hoursTypeToDisplay={hourType}
          rateTypeUnit={getDisplayRateTypeUnit(assignmentWorkDays, idx)}
          rateTypeToDisplay={rateType}
          rateToDisplay={rateToDisplay}
        />
      ))}
    </TableRow>
  );
};
