import { EmployeeId } from 'api/actions/getPerson/getPerson.types';
import { TimesheetWorkTime } from 'api/types/Timesheet.types';

export type SelectedWorkTimeCell = {
  date: string;
  employeeId: EmployeeId;
  items: TimesheetWorkTime[];
  isRoot: boolean;
};

type Listener = () => void;

const selectedCellRef = { current: null as SelectedWorkTimeCell | null };
const listeners = new Set<Listener>();

// TODO: think about better way to do this not causing waterfall of rerenders in WorkTimeCell
export const selectedCellStore = {
  get: () => selectedCellRef.current,
  set: (cell: SelectedWorkTimeCell | null) => {
    selectedCellRef.current = cell;
    listeners.forEach((listener) => listener());
  },
  subscribe: (listener: Listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  },
};
