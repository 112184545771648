import { WorkStatementTeamMemberWorkTimeEntry } from 'api/types/BillingSummary.types';
import { RATE_TYPES, RateType } from 'api/types/Rate.types';
import { DataMismatch } from 'api/types/Timesheet.types';

export const formatWorkTime = (value: number, rateType?: RateType): string => {
  const displayRate = rateType === RATE_TYPES.daily ? 'day' : rateType === RATE_TYPES.hourly ? 'hour' : undefined;
  if (!displayRate) return value.toString();

  const formatter = new Intl.NumberFormat('en', {
    style: 'unit',
    unit: displayRate,
    unitDisplay: 'narrow',
  });
  return formatter.format(value);
};

export const formatMismatchWorkTime = (showTimesheetSnapshot: boolean, mismatch?: DataMismatch): string => {
  if (!mismatch) return '';

  const key = showTimesheetSnapshot ? 'current' : 'snapshot';

  const dayValue = mismatch.days?.[key] ?? 0;
  const hourValue = mismatch.hours?.[key] ?? 0;

  const formattedDays = dayValue > 0 ? formatWorkTime(dayValue, RATE_TYPES.daily) : '';
  const formattedHours = hourValue > 0 ? formatWorkTime(hourValue, RATE_TYPES.hourly) : '';

  return [formattedDays, formattedHours].filter(Boolean).join(' ');
};

export const calculateWorkTimeRate = (workTime: WorkStatementTeamMemberWorkTimeEntry): number => {
  const { ratePercentage, rateValue } = workTime;
  return (ratePercentage / 100) * rateValue;
};
