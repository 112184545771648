import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoute } from 'routing/AppRoute.enum';
import { getMaintenanceMode, MAINTENANCE_MODE_KEY } from 'utils/maintenanceMode';

export const useMaintenanceMode = (isAuthenticated: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(getMaintenanceMode());

  useEffect(() => {
    const updateMaintenanceState = () => {
      setIsMaintenanceMode(getMaintenanceMode());
    };

    window.addEventListener(MAINTENANCE_MODE_KEY, updateMaintenanceState);
    return () => window.removeEventListener(MAINTENANCE_MODE_KEY, updateMaintenanceState);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    const shouldNavigateToMaintenance = isMaintenanceMode && location.pathname !== AppRoute.maintenance;
    const shouldNavigateToDashboard = !isMaintenanceMode && location.pathname === AppRoute.maintenance;

    if (shouldNavigateToMaintenance) navigate(AppRoute.maintenance);
    if (shouldNavigateToDashboard) navigate(AppRoute.dashboard);
  }, [isMaintenanceMode, location.pathname, navigate]);

  return { isMaintenanceMode };
};
