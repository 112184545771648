import { useState } from 'react';

import { ProjectStage } from 'api/types/ProjectStage.enum';
import { AssignmentInfoContextController } from 'context/assignmentInfo/assignmentInfoContextController/AssignmentInfoContextController';
import { AssignmentsFiltersContextController } from 'context/assignmentsFilters/assignmentsFiltersContextController/AssignmentsFiltersContextController';
import { ProjectDetailsContextController } from 'context/projectDetails/projectDetailsContextController/ProjectDetailsContextController';
import { ProjectDiscrepancyContextController } from 'context/projectDiscrepancy/projectDiscrepancyContextController/ProjectDiscrepancyContextController';
import { ProjectTimesheetContextController } from 'context/projectTimesheet/projectTimesheetContextController/ProjectTimesheetContextController';
import { ProjectTimesheetDataContextController } from 'context/projectTimesheet/projectTimesheetDataContextController/ProjectTimesheetDataContextController';
import { useClient } from 'hooks/useClient/useClient';
import { useLocale } from 'hooks/useLocale/useLocale';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { Loader } from 'ui/loader/Loader';

import { ClientTimesheetAccordionItem } from './clientTimesheetAccordionItem/ClientTimesheetAccordionItem';

export const ClientTimesheets = () => {
  const [firstTimesheetMonthStartDate, setFirstTimesheetMonthStartDate] = useState(new Date());
  const [tsNumberToShowNav, setTsNumberToShowNav] = useState(0);

  const { clientDetails, isLoading: isClientLoading } = useClient();
  const { t } = useLocale();

  if (isClientLoading) {
    return (
      <CardWrapper>
        <Loader />;
      </CardWrapper>
    );
  }

  const activeProjects = clientDetails
    ? clientDetails.client.projects.filter(({ stage }) => [ProjectStage.ongoing, ProjectStage.closing].includes(stage))
    : [];

  return (
    <CardWrapper>
      {activeProjects.length ? (
        <>
          {activeProjects.map((project, index) => (
            <ProjectDetailsContextController key={project.id} projectId={project.id}>
              <ProjectDiscrepancyContextController projectId={project.id}>
                <AssignmentsFiltersContextController>
                  <AssignmentInfoContextController>
                    <ProjectTimesheetContextController>
                      <ProjectTimesheetDataContextController>
                        <ClientTimesheetAccordionItem
                          timesheetNumber={index}
                          firstTimesheetMonthStartDate={firstTimesheetMonthStartDate}
                          setFirstTimesheetMonthStartDate={setFirstTimesheetMonthStartDate}
                          tsNumberToShowNav={tsNumberToShowNav}
                          setTsNumberToShowNav={setTsNumberToShowNav}
                        />
                      </ProjectTimesheetDataContextController>
                    </ProjectTimesheetContextController>
                  </AssignmentInfoContextController>
                </AssignmentsFiltersContextController>
              </ProjectDiscrepancyContextController>
            </ProjectDetailsContextController>
          ))}
          {tsNumberToShowNav < 0 && (
            <EmptyState illustration="emptyState" title={t('client.timesheets.not.found.title')} message="" />
          )}
        </>
      ) : (
        <EmptyState illustration="emptyState" title={t('client.timesheets.not.found.title')} message="" />
      )}
    </CardWrapper>
  );
};
