import { Dialog } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppRoute, ClientDetailsRoute, ProjectDetailsRoute } from 'routing/AppRoute.enum';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { DiscrepancyWorkTimeWarning } from '../../discrepancyWorkTimeWarning/DiscrepancyWorkTimeWarning';
import { WarningMessage } from '../../discrepancyWorkTimeWarning/warningMessage/WarningMessage';

import * as styles from './ApproveBillingCycleWarningModal.styles';
import { ApproveBillingCycleWarningModalProps } from './ApproveBillingCycleWarningModal.types';

export const ApproveBillingCycleWarningModal = ({
  open,
  onClose,
  title = '',
  isFormValid = true,
  forceConfirm,
  assignments,
}: ApproveBillingCycleWarningModalProps) => {
  const { t } = useLocale();
  const { pathname } = useLocation();
  const isClientView = pathname.includes(AppRoute.clients);

  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogInnerWrapper
        onClose={onClose}
        isFormValid={isFormValid}
        onSubmit={forceConfirm}
        title={title}
        submitText={t('projectDetails.billingSummary.error.approve')}
      >
        <DiscrepancyWorkTimeWarning
          message={<WarningMessage assignments={assignments} />}
          actionLabel={'projectDetails.billingSummary.warning.actionLabel'}
          to={`../${isClientView ? ClientDetailsRoute.timesheets : ProjectDetailsRoute.timesheet}`}
          modifiedText={true}
        />
      </DialogInnerWrapper>
    </Dialog>
  );
};
