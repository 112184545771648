export enum AppRoute {
  homepage = '/',
  login = '/login',
  logout = '/logout',
  forbidden = '/forbidden',
  dashboard = '/dashboard',
  createProject = '/create-project',
  projectDetails = '/projects/:projectId',
  projects = '/projects',
  people = '/people',
  clients = '/clients',
  clientDetails = '/clients/:clientId',
  personDetails = '/people/:personId',
  needs = '/needs',
  maintenance = '/maintenance',
}

export enum ProjectDetailsRoute {
  overview = 'overview',
  team = '',
  history = 'history',
  timesheet = 'timesheet',
  summary = 'summary',
  report = 'report',
  invoice = 'invoice',
}

export enum ClientDetailsRoute {
  overview = 'overview',
  projects = '',
  timesheets = 'timesheets',
  summary = 'summary',
  invoices = 'invoices',
}
