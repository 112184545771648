import { PersonRate } from 'api/actions/getPerson/getPerson.types';
import { getPersonAssignmentsQuery } from 'api/actions/getPersonAssignments/getPersonAssignments';
import {
  GetPersonAssignmentsParams,
  GetPersonAssignmentsResponse,
} from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { convertRateToDecimal } from 'utils/convertRate';
import { peopleKeys } from 'utils/queryKeys';

export const usePersonAssignments = (personId: string) => {
  const { isAuthenticated } = useAuth();

  const { data, ...response } = useQuery<GetPersonAssignmentsParams, GetPersonAssignmentsResponse>(
    peopleKeys.singlePersonAssignments(personId),
    getPersonAssignmentsQuery(personId),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );

  if (!data) {
    return { data, ...response };
  }

  const mappedData: GetPersonAssignmentsResponse = {
    ...data,
    assignments: data.assignments.map((assignment) => ({
      ...assignment,
      currentRate: assignment.currentRate && convertRateToDecimal(assignment.currentRate),
      currentRateTable:
        assignment.currentRateTable &&
        (Object.fromEntries(
          Object.entries(assignment.currentRateTable).map(([currency, value]) => [
            currency,
            convertRateToDecimal(value),
          ]),
        ) as PersonRate['rates']),
      person: {
        ...assignment.person,
        rate: assignment.person.rate && convertRateToDecimal(assignment.person.rate),
      },
    })),
  };

  return { data: mappedData, ...response };
};
