import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { peopleKeys } from 'utils/queryKeys';
import { getPeople } from 'api/actions/getPeople/getPeople';
import { GetPeopleParams, GetPeopleResponse } from 'api/actions/getPeople/getPeople.types';

import { UsePeopleParams } from './usePeople.types';

export const usePeople = (params?: UsePeopleParams) => {
  const { isAuthenticated } = useAuth();
  const queryKey = [...peopleKeys.peopleList, params];

  let args: GetPeopleParams = {};

  if (params?.isSupervisor) {
    args = { ...args, isSupervisor: params.isSupervisor, non_dev_roles: true };
  }

  return useQuery<GetPeopleParams, GetPeopleResponse>(queryKey, getPeople, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    args: {
      ...args,
      limit: 999,
    },
  });
};
