import { useEffect, useMemo, useState } from 'react';

import { Box, Popover } from '@mui/material';
import { Button, Typography } from 'lux/components';

import { HourType } from 'api/types/HourType.enum';
import { RATE_TYPES } from 'api/types/Rate.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { Translation } from 'ui/translation/Translation';
import { percentageInputNumberParser, workTimeInputNumberParser } from 'utils/createInputNumberChangeParser';
import { isAbsentHourType } from 'utils/is-absent-hour-type';

import * as styles from './WorkTimePopover.styles';
import { WorkTimePopoverForm, WorkTimePopoverFormSubmitHandler, WorkTimePopoverProps } from './WorkTimePopover.types';
import { useWorkTimePopoverForm } from './WorkTimePopoverForm';

const baseRegularRate = 100;
const baseOverTimeRate = 150;

export const WorkTimePopover = ({
  workTimeEntries,
  anchorEl,
  onClose,
  onSave,
  hoursTypeToDisplay,
}: WorkTimePopoverProps) => {
  const { t } = useLocale();
  const rateType = useMemo(() => workTimeEntries?.[0]?.rateType, [workTimeEntries]);
  const defaultValues: WorkTimePopoverForm = {
    regularWorkAmount: workTimeEntries.find((workTime) => workTime.type === HourType.regular)?.amount ?? 0,
    regularWorkRate:
      workTimeEntries.find((workTime) => workTime.type === HourType.regular)?.ratePercentage ?? baseRegularRate,
    overTimeAmount: workTimeEntries.find((workTime) => workTime.type === HourType.overhour)?.amount ?? 0,
    overTimeRate:
      workTimeEntries.find((workTime) => workTime.type === HourType.overhour)?.ratePercentage ?? baseOverTimeRate,
  };
  const { control, reset, handleSubmit, isValid, watch, setError, clearErrors, trigger } = useWorkTimePopoverForm(
    defaultValues,
    rateType,
  );
  const regularWorkAmount = Number(watch('regularWorkAmount'));
  const overTimeAmount = Number(watch('overTimeAmount'));

  useEffect(() => {
    const hoursSum = regularWorkAmount + overTimeAmount;

    if (hoursSum > 24) {
      setError('regularWorkAmount', {});
      setError('overTimeAmount', {});
    } else {
      trigger();
      clearErrors(['regularWorkAmount', 'overTimeAmount']);
    }
  }, [overTimeAmount, regularWorkAmount]);

  useEffect(() => {
    reset(defaultValues);
  }, [anchorEl]);

  const handleSave: WorkTimePopoverFormSubmitHandler = ({
    regularWorkAmount,
    regularWorkRate,
    overTimeAmount,
    overTimeRate,
  }) => {
    if (!onSave) {
      return;
    }

    if (
      onClose &&
      defaultValues.regularWorkAmount === regularWorkAmount &&
      defaultValues.regularWorkRate === regularWorkRate &&
      defaultValues.overTimeAmount === overTimeAmount &&
      defaultValues.overTimeRate === overTimeRate
    ) {
      return onClose();
    }

    onSave([
      {
        type: HourType.regular,
        amount: regularWorkAmount || 0,
        ratePercentage: regularWorkAmount ? regularWorkRate : baseRegularRate,
        rateType: rateType,
      },
      ...(overTimeAmount > 0
        ? [
            {
              type: HourType.overhour,
              amount: overTimeAmount || 0,
              ratePercentage: overTimeAmount ? overTimeRate : baseOverTimeRate,
              rateType: RATE_TYPES.hourly,
            },
          ]
        : []),
    ]);
  };

  const sumOfHours = workTimeEntries.reduce((sum, workTime) => sum + workTime.amount, 0);
  const isAbsent = !!workTimeEntries.find((workTime) => isAbsentHourType(workTime.type)) && sumOfHours <= 0;
  const [shouldDisplayConfirmation, setShouldDisplayConfirmation] = useState(isAbsent);

  const handleConfirmEditing = () => {
    setShouldDisplayConfirmation(false);
  };

  const showBothInputs = hoursTypeToDisplay === HourType.total;

  return (
    <Popover
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      {shouldDisplayConfirmation ? (
        <Box sx={styles.confirmation}>
          <Typography variant="subtitle1" color="text.secondary">
            <Translation id="projectDetails.timesheet.changeWorkTimeConfirmation.title" />
          </Typography>
          <Box sx={styles.actionButtonsWrapper}>
            <Button size="small" variant="outlined" onClick={onClose}>
              <Translation id="button.cancel" />
            </Button>
            <Button size="small" onClick={handleConfirmEditing} sx={styles.confirmationButton}>
              <Translation id="projectDetails.timesheet.changeWorkTimeConfirmation.confirmButton" />
            </Button>
          </Box>
        </Box>
      ) : (
        <form onSubmit={handleSubmit(handleSave)}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.inputsWrapper}>
              <Typography sx={styles.rateTypeLabel}>
                <Translation id="projectDetails.timesheet.rateType" />
              </Typography>
              <Typography sx={styles.rateType}>{rateType}</Typography>
            </Box>
            {(showBothInputs || hoursTypeToDisplay === HourType.regular) && (
              <Box sx={styles.inputsWrapper}>
                <FormTextField
                  sx={styles.percentageRateInput}
                  name="regularWorkAmount"
                  size="small"
                  control={control}
                  label={t('projectDetails.timesheet.regularWorks')}
                  fullWidth
                  endAdornment={rateType === RATE_TYPES.daily ? 'd' : 'h'}
                  hideHelperText
                  inputLabelShrink
                  autoFocus
                  onChangeParser={workTimeInputNumberParser}
                />
                <FormTextField
                  sx={styles.percentageRateInput}
                  name="regularWorkRate"
                  size="small"
                  control={control}
                  label="%"
                  fullWidth
                  endAdornment="%"
                  hideHelperText
                  inputLabelShrink
                  onChangeParser={percentageInputNumberParser}
                />
              </Box>
            )}
            {(showBothInputs || hoursTypeToDisplay === HourType.overhour) && (
              <Box sx={styles.inputsWrapper}>
                <FormTextField
                  sx={styles.percentageRateInput}
                  name="overTimeAmount"
                  size="small"
                  control={control}
                  label={t('projectDetails.timesheet.overtime')}
                  fullWidth
                  endAdornment="h"
                  hideHelperText
                  inputLabelShrink
                  onChangeParser={workTimeInputNumberParser}
                />
                <FormTextField
                  sx={styles.percentageRateInput}
                  name="overTimeRate"
                  size="small"
                  control={control}
                  label="%"
                  fullWidth
                  endAdornment="%"
                  hideHelperText
                  inputLabelShrink
                  onChangeParser={percentageInputNumberParser}
                />
              </Box>
            )}
            <Box sx={styles.actionButtonsWrapper}>
              <Button size="small" variant="outlined" onClick={onClose}>
                <Translation id="button.cancel" />
              </Button>
              <Button type="submit" size="small" disabled={!isValid}>
                <Translation id="button.save" />
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Popover>
  );
};
