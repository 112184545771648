import { SxProps } from '@mui/material';
import { Styles } from 'lux/theme';

import { markerBorderLineWidth, startDayCellWidth } from 'utils/timesheetProjectStartDayIndicator';

export const cell = ({
  isProjectStartDayMarker,
  isCellEdited,
}: {
  isProjectStartDayMarker: boolean;
  isCellEdited?: boolean;
}): SxProps => ({
  ...(isProjectStartDayMarker && {
    borderLeft: `${markerBorderLineWidth}px solid orange`,
    width: startDayCellWidth,
    position: 'relative',

    // we need to mask a piece of bottom border in a cell with orange color
    '&:before': {
      position: 'absolute',
      left: -markerBorderLineWidth,
      top: -2,
      content: '""',
      display: 'block',
      height: 2,
      width: markerBorderLineWidth,
      backgroundColor: 'orange',
    },
  }),

  ...(isCellEdited && {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      top: 0,
      left: 0,
      content: '""',
      display: 'block',
      borderRight: 'solid 12px transparent',
      borderTop: 'solid 12px',
      borderTopColor: 'border.default',
    },
  }),

  '.MuiGrid-item': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

export const loader: SxProps = {
  '.MuiCircularProgress-root': {
    width: '20px !important',
    height: '20px !important',
  },
};

export const popper: Styles = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'other.tooltip',
    boxShadow: (theme) => theme.luxShadows[500],
    color: 'color.text.white',
    maxWidth: 250,
  },
  '& .MuiTooltip-arrow': {
    '&::before': {
      backgroundColor: 'other.tooltip',
      boxShadow: (theme) => theme.luxShadows[500],
    },
  },
};
